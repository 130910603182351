const TYPE_MEME = 1;
const TYPE_REVERSE = 2;
const TYPE_STOP_MOTION = 3;
const TYPE_LOOP = 4;
const TYPE_MUTE = 5;
const TYPE_VIDEO_MERGE = 6;
const TYPE_SPEED = 7;
const TYPE_VIDEO_CUT = 8;
const TYPE_CROP = 9;
const TYPE_VIDEO_RESIZE = 10;
const TYPE_VIDEO_ROTATE = 11;
const TYPE_VIDEO_FLIP = 12;
const TYPE_VIDEO_ADJUST = 13;
const TYPE_VIDEO_FILTER = 14;
const TYPE_VIDEO_COMPRESS = 15;
const TYPE_AUDIO_MERGE = 21;
const TYPE_AUDIO_CUT = 20;
const TYPE_SLIDESHOW_MAKER = 41;
const TYPE_VIDEO_MAKER = 42;
const TYPE_VIDEO_IMPOSE_AUDIO = 43;
const TYPE_ADD_SUBTITLES = 50;
const TYPE_SPEECH_TO_TEXT = 52;
const TYPE_TRANSLATE_VIDEO = 53;
const TYPE_IMAGE_DPI_CONVERTER = 31;

const TYPE_OPERATION_DPI_CONVERT = 'DPI';

// FORMATS
const FORMAT_MP4 = 12;
const FORMAT_MP3 = 9;
const KEEP_FORMAT = 0;

const FILE_TYPE_VIDEO = 0;
const FILE_TYPE_IMAGE = 1;
const FILE_TYPE_LOCATION = 3;
const FILE_TYPE_AUDIO = 2;

const CUT_MODE_EXTRACT = 'extract';
const CUT_MODE_DROP = 'drop';

const CONVERSION_STATUS_CREATED = 'C';
const CONVERSION_STATUS_PROCESSING = 'P';
const CONVERSION_STATUS_PROCESSED = 'F'; // Finished :)
const CONVERSION_STATUS_ERROR = 'E';

const CONVERSION_STATE_WATERMARKED = 'W';
const CONVERSION_STATE_CLEAN = 'C';

const SOCIAL_ACCOUNT_GOOGLE = 'G';
const SOCIAL_ACCOUNT_APPLE = 'A';

const AVAILABLE_LOCALES = [
  'de',
  'en',
  'es',
  'fr',
  'it',
  'pt',
  'ru',
];

const GLOBAL_WHITELISTED_PARAMS = [
  'pollingMethod',  // Added in the convert action in store/modules/editor.js
  'projectID',      // Set (retrieved via fetchParams) when we load an existent project for editing.
  'type',           // Tool ID (from config.TYPE_* in config.js)
  'sequence',       // Set for every request via axios.interceptors.request in bootstrap.js
  'locale',         // Set for every request via axios.interceptors.request in Helpers.js::setAxiosSequence
  'format',         // Set via storeParams in Footer.vue on every export
  'resolution',     // Set via storeParams in Footer.vue on every export
  'quality',        // Set via storeParams in Footer.vue on every export
];

const TOOL_REQUIRED_PARAMS = {
  [TYPE_MEME]: [
    // Export Params:
    'dataURLTop',
    'dataURLBottom',
    'video_scale_mode',
    'background_color',
    'image_place_mode',
    'mute',
    'aspectRatio',

    // Editor Params:
    'aspect',
    'mute',
    'zoom',
    'selectedFontId',
    'selectedColorId',
    'selectedlineHeightId',
    'selectedAlign',
    'bgcolor',
    'selectedTemplateID',
    'canvases',
  ],
  [TYPE_REVERSE]: [
    'speed',
    'mute',
  ],
  [TYPE_STOP_MOTION]: [
    'speed',
    'rate',
    'reverse',
    'mute',
  ],
  [TYPE_LOOP]: [
    'loopCount',
  ],
  [TYPE_VIDEO_MERGE]: [
    'duration',
    'crossfade',
    'crossfadeDurations',
    'aspect',
    'zoom',
    'startAudioTimeOnStream', //Not really used
    'videoVolume',
    'audioVolume',
    'isAudioLoop',
    'leftBorderTime',
    'rightBorderTime',
  ],
  [TYPE_SPEED]: [
    'speed',
    'mute',
  ],
  [TYPE_VIDEO_CUT]: [
    'leftBorderTime',
    'rightBorderTime',
    'fadeIn',
    'fadeOut',
    'fadeInDuration',
    'fadeOutDuration',
    'mode',
    'crossfade',
    'crossfadeDuration',
  ],
  [TYPE_CROP]: [
    // Export Params:
    'scrap',

    // Editor Params:
    'selectedAspectId',
  ],
  [TYPE_VIDEO_RESIZE]: [
    // Export Params:
    'aspectValueText',
    'zoom',
    'positionX',
    'positionY',
    'background',

    // Editor Params:
    'preset',
    'schema',
    'zoomType',
    'custom',
    'customPreset',
    'effect',
  ],
  [TYPE_VIDEO_ROTATE]: [
    'rotate',
  ],
  [TYPE_VIDEO_FLIP]: [
    'flipX',
    'flipY',
  ],
  [TYPE_VIDEO_ADJUST]: [
    'adjust',
  ],
  [TYPE_VIDEO_FILTER]: [
    'filter',
  ],
  [TYPE_VIDEO_COMPRESS]: [
    'audioTrackMode',
    'compressionMode',
  ],
  [TYPE_AUDIO_MERGE]: [
    'crossfade',
    'crossfadeDurations',
  ],
  [TYPE_AUDIO_CUT]: [
    'leftBorderTime',
    'rightBorderTime',
    'mode',
    'fadeIn',
    'fadeOut',
    'crossfade',
    'crossfadeDuration',
  ],
  [TYPE_SLIDESHOW_MAKER]: [
    'duration',
    'crossfade',
    'crossfadeDurations',
    'aspect',
    'zoom',
    'startAudioTimeOnStream', //Not really used
    'videoVolume',
    'audioVolume',
    'isAudioLoop',
    'leftBorderTime',
    'rightBorderTime',
  ],
  [TYPE_VIDEO_MAKER]: [
    'duration',
    'crossfade',
    'crossfadeDurations',
    'aspect',
    'zoom',
    'startAudioTimeOnStream', //Not really used
    'videoVolume',
    'audioVolume',
    'isAudioLoop',
    'leftBorderTime',
    'rightBorderTime',
  ],
  [TYPE_VIDEO_IMPOSE_AUDIO]: [
    'audioVolume',
    'videoVolume',
    'isAudioLoop',
    'leftBorderTime',
    'rightBorderTime',
    'startAudioTimeOnStream',
  ],
  [TYPE_ADD_SUBTITLES]: [
    // Export Params:
    'screens',
    'subtitlesRenderStyles',

    // Editor Params:
    'subtitles',
    'styles',
    'recentFonts',
  ],
  [TYPE_SPEECH_TO_TEXT]: [
    // Export Params:
    'screens',
    'subtitlesRenderStyles',

    // Editor Params:
    'subtitles',
    'styles',
    'recentFonts',
  ],
  [TYPE_TRANSLATE_VIDEO]: [
    // Export Params:
    'screens',
    'subtitlesRenderStyles',

    // Editor Params:
    'subtitles',
    'styles',
    'recentFonts',

    // Translation:
    'translationLanguage',
    'translationVoice',
    'isIncludeSubtitles',
    'isChangeSpeech',
  ],
  [TYPE_IMAGE_DPI_CONVERTER]: [
    'dpi',
  ],
};

const MAX_TIMEOUT_DELAY_POSSIBLE = Math.pow(2, 31) - 1;
const LS_KEY_TRANSLATE_TO_RECENT_CODES = 'stt_translateToRecentCodes';
const LS_KEY_ORIGINAL_LANGUAGE_RECENT_CODES = 'stt_originalLanguageRecentCodes';
const SS_KEY_SUBTITLES_DRAFT = 'stt_subtitlesDraft';
const SS_KEY_SUBTITLES_PLAYER_TOOLTIP_HIDDEN = 'stt_subtitlesPlayerTooltipHidden';
const SS_KEY_SUBTITLES_TOOLBAR_TOOLTIP_HIDDEN = 'stt_subtitlesToolbarTooltipHidden';
const SS_KEY_SUBTITLES_LAST_ORIGINAL_LANGUAGE = 'stt_subtitlesLastOriginalLanguage';
const SS_KEY_SUBTITLES_LAST_TRANSLATE_LANGUAGE = 'stt_subtitlesLastTranslateLanguage';
const SUBTITLES_TOOLTIPS_REAPPEAR_MS = 30*24*3600*1000; // 30 days

export default {
  TYPE_MEME,
  TYPE_REVERSE,
  TYPE_STOP_MOTION,
  TYPE_LOOP,
  TYPE_MUTE,
  TYPE_VIDEO_MERGE,
  TYPE_SPEED,
  TYPE_VIDEO_CUT,
  TYPE_CROP,
  TYPE_VIDEO_FILTER,
  TYPE_VIDEO_ROTATE,
  TYPE_VIDEO_RESIZE,
  TYPE_VIDEO_FLIP,
  TYPE_VIDEO_ADJUST,
  TYPE_VIDEO_COMPRESS,
  TYPE_SLIDESHOW_MAKER,
  TYPE_VIDEO_MAKER,
  TYPE_AUDIO_MERGE,
  TYPE_AUDIO_CUT,
  TYPE_VIDEO_IMPOSE_AUDIO,
  TYPE_ADD_SUBTITLES,
  TYPE_SPEECH_TO_TEXT,
  TYPE_TRANSLATE_VIDEO,

  TYPE_IMAGE_DPI_CONVERTER,
  TYPE_OPERATION_DPI_CONVERT,

  KEEP_FORMAT,
  FORMAT_MP4,
  FORMAT_MP3,

  FILE_TYPE_VIDEO,
  FILE_TYPE_IMAGE,
  FILE_TYPE_LOCATION,
  FILE_TYPE_AUDIO,

  CUT_MODE_EXTRACT,
  CUT_MODE_DROP,

  CONVERSION_STATUS_CREATED,
  CONVERSION_STATUS_PROCESSING,
  CONVERSION_STATUS_PROCESSED,
  CONVERSION_STATUS_ERROR,

  CONVERSION_STATE_WATERMARKED,
  CONVERSION_STATE_CLEAN,

  SOCIAL_ACCOUNT_GOOGLE,
  SOCIAL_ACCOUNT_APPLE,

  AVAILABLE_LOCALES,
  GLOBAL_WHITELISTED_PARAMS,
  TOOL_REQUIRED_PARAMS,
  MAX_TIMEOUT_DELAY_POSSIBLE,

  LS_KEY_TRANSLATE_TO_RECENT_CODES,
  LS_KEY_ORIGINAL_LANGUAGE_RECENT_CODES,
  SS_KEY_SUBTITLES_DRAFT,
  SS_KEY_SUBTITLES_PLAYER_TOOLTIP_HIDDEN,
  SS_KEY_SUBTITLES_TOOLBAR_TOOLTIP_HIDDEN,
  SS_KEY_SUBTITLES_LAST_ORIGINAL_LANGUAGE,
  SS_KEY_SUBTITLES_LAST_TRANSLATE_LANGUAGE,
  SUBTITLES_TOOLTIPS_REAPPEAR_MS,
};
