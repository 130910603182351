import axios from 'axios';

import Echo from '../echo';

class WS {
  constructor(channelName) {
    this.channelName = channelName;
    this.listeners = {};
    this.callbackConnectSuccess = () => {};
    this.callbackConnectError = () => {};
    this.eventsReceived = {};
  }

  bind(eventName, callback) {
    if (eventName === 'subscription_succeeded') {
      this.callbackConnectSuccess = callback;
    } else if (eventName === 'subscription_error') {
      this.callbackConnectError = callback;
    } else {
      this.listeners[eventName] = callback;
      this.eventsReceived[eventName] = false;
    }
  }

  listen() {
    const channel = Echo.private(this.channelName);

    for (const [eventName, callback] of Object.entries(this.listeners)) {
      channel.listen(eventName, eventPayload => {
        this.eventsReceived[eventName] = true;
        callback(eventPayload, this);
      });
    }

    channel.subscription.bind('pusher:subscription_error', () => {
      /**
       * Websocket is up, so detectBestSupportedPollingMethod() chose it.
       * But channel subscription is faulty.
       * The user will see an error and we will disconnect Echo to ensure that the PC method will be selected on the next user try.
       * @todo Instead of an error, we need to silently switch to PC method.
       */
      this.disconnect();

      this.callbackConnectError();
    });

    channel.subscription.bind('pusher:subscription_succeeded', () => {
      // Retrieve history of missed notifications. Helpful if connection to WS took a lot of time.
      for (const eventName of Object.keys(this.listeners)) {
        if (!this.eventsReceived[eventName]) {
          const historyPollingURL = `/polling/${this.channelName.replace('@', '/')}/${eventName.split('\\').pop()}`;

          axios
            .get(historyPollingURL)
            .then(response => {
              if (!this.eventsReceived[eventName]) {
                if ('data' in response && Array.isArray(response.data)) {
                  response.data.forEach(eventPayload => {
                    this.listeners[eventName](eventPayload, this);
                  });
                }
              }
            })
            .catch(() => {});
        }
      }

      this.callbackConnectSuccess();
    });
  }

  leave() {
    Echo.leave(this.channelName);
  }

  disconnect() {
    Echo.disconnect();
  }
}

export default WS;
