export function cleanAutoModalPayment() {
  window.location.hash = '';
}

export function getFileIDFromURL() {
  const loc = window.location.toString();
  const reg1 = /\/result\/(\d+)/;
  return reg1.test(loc) ? reg1.exec(loc)[1] : undefined;
}

export function isAutoModalPayment(hash = 'plan') {
  return window.location.hash === `#${hash}`;
}

export function isAutoModalPaymentForEdit() {
  return window.location.hash.startsWith('#plan-edit');
}

export function getEditAppIDFromURL() {
  return window.location.hash.startsWith('#plan-edit-')
    ? window.location.hash.replace('#plan-edit-', '')
    : undefined;
}

//@todo Need to be DRYed with backend.
export function getEditorPrefillURL(fileID, appID) {
  const routeSlug = {
    'speed-video': 'change-video-speed',
    'video-impose-audio': 'add-audio-to-video',
    'add-subtitles': 'add-subtitles-to-video',
  };
  return `/editor/${routeSlug[appID] || appID}/edit-${fileID}`;
}

export function getRedirectUrl(defaultRedirectUrl, isPostPaymentStage = false) {
  let localePrefix = '';
  try {
    const locale = $('html').attr('lang');
    localePrefix = locale && locale !== 'en' ? `/${locale}` : '';
  } catch (e) {
  }

  if (isAutoModalPayment()) {
    cleanAutoModalPayment();
    if (sessionStorage.getItem('redirect-url')) {
      const redirectURL = sessionStorage.getItem('redirect-url');
      sessionStorage.removeItem('redirect-url');

      return redirectURL;
    } else {
      return window.location.href;
    }
  } else if (sessionStorage.getItem('editorFlow.isInsideIframe') === 'yes') {
    const projectKey = sessionStorage.getItem('editorFlow.projectKey');
    const isResultPage = sessionStorage.getItem('editorFlow.isResultPage');
    const message = {
      type: 'fastspring',
      event: 'fs.modal.closed',
    };

    if (projectKey !== null) {
      message.projectKey = projectKey;
    }

    let targetOrigin = `${window.location.origin}/editor`;
    if (projectKey !== null) {
      targetOrigin = `${targetOrigin}/${projectKey}`;
    }

    if (isResultPage !== null) {
      targetOrigin = `${targetOrigin}/export-result`;
    }

    if (isPostPaymentStage) {
      // only post this message to origin domain + `/editor` path (the agreed URL editor will run on).
      // make sure to update it if editor URL changes.
      window.top.postMessage(message, targetOrigin);
    }

    sessionStorage.removeItem('editorFlow.isInsideIframe');
    sessionStorage.removeItem('editorFlow.projectKey');

    if (!isPostPaymentStage) {
      return window.top.location.href;
    } else {
      return null;
    }
  } else if (sessionStorage.getItem('edit-id')) {
    const currentFileID = getFileIDFromURL();
    const fileID = sessionStorage.getItem('edit-id');
    sessionStorage.removeItem('edit-id');

    const appID = sessionStorage.getItem('edit-app-id');
    if (appID !== null) {
      sessionStorage.removeItem('edit-app-id');
      return getEditorPrefillURL(fileID, appID);
    } else {
      if (currentFileID === fileID) {
        return window.location.href;
      } else {
        return `${localePrefix}/project/${fileID}/result#edit`;
      }
    }
  } else if ($('[data-redirect-id]').length) {
    const fileID = $('[data-redirect-id]').data('redirect-id');
    return `${localePrefix}/project/${fileID}/result`;
  } else if (sessionStorage.getItem('redirect-url')) {
    const redirectURL = sessionStorage.getItem('redirect-url');
    sessionStorage.removeItem('redirect-url');
    return redirectURL;
  } else {
    return defaultRedirectUrl;
  }
}
