import DATA_JSON from '../data.json';
import {Duration} from 'luxon';
import UAParser from 'ua-parser-js';

export function isValidURL(url) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
}

export function getNameFormURL(url) {
  const lastURLPart = (url.match(/\/([^/]+)\/*$/) || ['', ''])[1];
  const fileName = (lastURLPart.match(/[^.]+(\.[^?#]+)?/) || [''])[0];
  return fileName.replace(/[^a-z0-9-]+/gi, '_'); // Simplest sanitization
}

export function prettifySize(bytes, fixed = 1) {
  let i = -1;
  const byteUnits = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    bytes = bytes / 1024;
    i++;
  } while (bytes > 1024);

  return `${Math.max(bytes, 0.1).toFixed(fixed)} ${byteUnits[i]}`;
}

export function isEqual(n1, n2) {
  return Math.abs(n1 - n2) < 0.00001;
}

export function timeFormat(sec) {
  if (isNaN(sec)) return '00:00';

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = Math.floor(sec - (hours * 3600) - (minutes * 60));

  const hDisplay = hours.toString().padStart(2, '0');
  const mDisplay = minutes.toString().padStart(2, '0');
  const sDisplay = seconds.toString().padStart(2, '0');

  return (hours > 0)
    ? `${hDisplay}:${mDisplay}:${sDisplay}`
    : `${mDisplay}:${sDisplay}`;
}

export const formatMediaFilesDuration = (value) => {
  let time = value;
  if (time < 1000) {
    time = 0;
  }

  const duration = Duration
    .fromObject({ milliseconds: time })
    .shiftTo('hours', 'minutes', 'seconds', 'milliseconds');

  let format = '%H:%M:%S';

  if (time < 60 * 60 * 1000) {
    format = format.replace('%H:', '');
  }

  const hours = duration.hours;
  const minutes = duration.minutes;
  const seconds = Math.round((duration.seconds * 1000 + duration.milliseconds) / 1000);

  const [
    displayHours,
    displayMinutes,
    displaySeconds,
  ] = [hours, minutes, seconds].map(item => item.toString().padStart(2, '0'));

  format = format.replace('%H', displayHours);
  format = format.replace('%M', displayMinutes);
  format = format.replace('%S', displaySeconds);

  return format;
};

export function timelineGridTimeFormat(time) {
  const pad = (n, z) => (`00${n}`).slice(-(z || 2));

  const ms = time % 1000;
  time = (time - ms) / 1000;

  const secs = time % 60;
  time = (time - secs) / 60;

  const m = time % 60;
  const h = (time - m) / 60;

  return (h === 0 || !h)
    ? `${m}:${pad(secs)}`
    : `${h}:${pad(m)}:${pad(secs)}`;
}

export function processMetricData(msg) {
  if (typeof window.gtag === 'function') {
    window.gtag('event', msg, {
      event_category: 'allTools',
      event_label: '',
      non_interaction: ((msg === 'vueMounted') || (msg === 'fileChooseDialog')),
    });
  } else if (typeof window.dataLayer === 'object') {
    window.dataLayer.push({
      event: 'metric-data',
      event_category: 'allTools',
      event_action: msg,
      event_label: '',
      event_value: '',
      event_non_interaction: ((msg === 'vueMounted') || (msg === 'fileChooseDialog')),
    });
  }
}

export function getEventXCoordinates(event) {
  const flagTouchEvent = ['touchstart', 'touchmove'].includes(event.type);

  return {
    x: flagTouchEvent ? event.touches[0].clientX : event.clientX,
  };
}

export function getEventCoordinates(event) {
  const flagTouchEvent = ['touchstart', 'touchmove'].includes(event.type);

  return {
    x: flagTouchEvent ? event.touches[0].clientX : event.clientX,
    y: flagTouchEvent ? event.touches[0].clientY : event.clientY,
  };
}

// Friendly reminder. Rounding of msec is not consistent:
// timeFormatMs(0.015) == "00:00.01"
// timeFormatMs(0.025) == "00:00.03"
export function timeFormatMs(seconds, padWithZeroHour = false) {
  seconds = Number(seconds);

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  //const ms = Math.floor((seconds - Math.floor(seconds)) * 100);    // 1.63 == "00:01.62"
  const ms = parseInt((seconds % 1).toFixed(2) * 100); // 1.63 == "00:01.63"

  const hD = h.toString();
  const mD = m.toString().padStart(2, '0');
  const sD = s.toString().padStart(2, '0');
  const msD = ms.toString().padStart(2, '0');

  return h > 0 || padWithZeroHour ? `${hD}:${mD}:${sD}.${msD}` : `${mD}:${sD}.${msD}`;
}

// Friendly reminder. Rounding of msec is not consistent:
// prepareTimeFormatFromDuration(0.015, 100) == "00:00.01"
// prepareTimeFormatFromDuration(0.025, 100) == "00:00.03"
export function prepareTimeFormatFromDuration(time, duration) {
  const padWithZeroHour = (Number(duration) >= 3600);
  return timeFormatMs(time, padWithZeroHour);
}

/*
* Time string to seconds
* Friendly reminder:
* msToSeconds('01:40.77') = 100.77000000000001
* msToSeconds('01:40.77') > 100.77 => TRUE
*/
export function msToSeconds(str) {
  const a = str.split(':').reverse();

  let time = 0;
  for (let i = 0; i < a.length; i++) {
    if (a[i]) {
      time += +a[i] * Math.pow(60, i);
    }
  }

  return time;
}

export function clearLocationHash() {
  if (window.location.hash.length) {
    if (typeof history.replaceState === 'function') {
      history.replaceState({}, window.document.title, window.location.href.split('#')[0]);
    } else {
      window.location.hash = '';
    }
  }
}

export function pushUTM(utm) {
  window.axios.post('/js/utm', {
    utm: utm,
  });
}

export function truncateString(text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function arrayNextOrFirst(array, current) {
  const index = array.indexOf(current);

  if (index >= 0 && index < array.length - 1) {
    return array[index + 1];
  }

  return array[0];
}

export function range(from, to, step) {
  if (to === undefined && step === undefined) {
    [from, to] = [0, from];
  }

  step = step || 1;

  const range = [];

  for (let x = from; (to - x) * step >= 0; x += step) {
    range.push(x);
  }

  return range;
}

export function round(number, precision = .01, method = 'round') {
  // Convert string numbers to a float
  number = parseFloat(number);

  // If there's no rounding precision, return the number
  if (!precision) {
    return number;
  }

  // Possible methods and their values
  const methods = {
    auto: 'round',
    up: 'ceil',
    down: 'floor',
  };

  // Do math!
  return (Math[methods[method] || 'round'](number / precision) * precision);
}

export function roundToFixed(number, precision = .01, fractionDigits = 2, method = 'round') {
  return parseFloat(
    round(number, precision, method).toFixed(fractionDigits)
  );
}

export function equalInPrecision(val1, val2, fractionDigits) {
  return Math.abs(val1 - val2) <= Math.pow(10, -fractionDigits);
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

/**
 * 1. If hash in the location - read it, check it, clear it from location, store it in session, return it
 * 2. If hash in the session - read it, check it, return it
 * 3. If defaultValue set - check it, store it in session, return it
 * 4. return null )
 *
 * @param defaultValue default sequence id if it's missing in the current window location
 * @param toolType type of the tool the function is being called from (a corresponding TYPE_* constant value from config.js). It's used to avoid reuse of files uploaded into other tools
 * @returns {null|string}
 */
export function sequenceFromHashOrDefault(defaultValue = null, toolType = null) {
  const hashSequence = window.top.location.hash.toString().replace('#sequence=', '');
  const r = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  if (r.test(hashSequence.toString())) {
    const hashString = hashSequence.toString();
    clearLocationHash();
    sessionStorage.setItem('sequence', hashString);

    if (toolType !== null) {
      sessionStorage.setItem('sequenceToolType', toolType.toString());
    }

    return hashString;
  }

  const storedSequence = sessionStorage.getItem('sequence');
  if (r.test(storedSequence)) {
    if (toolType > 0) {
      const storedToolType = sessionStorage.getItem('sequenceToolType');
      const storedToolTypeMatchesOrUnknown = (storedToolType && parseInt(storedToolType) === toolType) || !storedToolType;  // "unknown" is for "Continue in" from other tools or editor
      if (storedToolTypeMatchesOrUnknown) {
        return storedSequence;
      } else {
        sessionStorage.removeItem('sequence');
        sessionStorage.removeItem('sequenceToolType');
      }
    } else {
      return storedSequence;
    }
  }

  if (r.test(defaultValue)) {
    sessionStorage.setItem('sequence', defaultValue);
    if (toolType > 0) {
      sessionStorage.setItem('sequenceToolType', toolType.toString());
    }
    return defaultValue;
  }

  return null;
}

/**
 * Uses a random sequence id as a default value if the sequence hash is missing in the current window location
 *
 * @param toolType id of the tool the function is being called from (a corresponding TYPE_* constant value from config.js). It's used to avoid reuse of files uploaded into other tools
 * @returns {string|null}
 */
export function sequenceFromHashOrRandom(toolType = 0) {
  return sequenceFromHashOrDefault(require('uuid-random')(), toolType);
}

export function setAxiosSequence(sequence) {
  const storageKey = 'axiosRequestInterceptorId';

  let interceptorId = sessionStorage.getItem(storageKey);
  if (interceptorId !== null) {
    window.axios.interceptors.request.eject(parseInt(interceptorId));
    sessionStorage.removeItem(storageKey);
  }

  interceptorId = window.axios.interceptors.request.use(config => {
    /**
     * Axios unshift interceptors instead of pushing them, so calling setAxiosSequence multiple times doesn't override 'sequence' as you might expect.
     * To overcome this limitation, we'll check if the sequence was already set and if so, we'll only use first interceptor from the chain.
     * @see https://github.com/axios/axios/blob/v0.19.2/lib/core/Axios.js#L53
     */
    const isSequenceSet = 'params' in config && 'sequence' in config.params;

    if (!isSequenceSet) {
      config.params = {
        ...config.params,
        sequence: sequence,
      };
    }

    return config;
  });

  sessionStorage.setItem(storageKey, interceptorId.toString());
}

export function clearSequenceAndParams() {
  sessionStorage.removeItem('params');
  sessionStorage.removeItem('sequence');
  sessionStorage.removeItem('sequenceToolType');
}

export function checkVideoSourcePlayability(source) {
  return new Promise(resolve => {
    const video = document.createElement('video');

    video.autoplay = false;
    video.muted = true;
    video.playsInline = true;

    video.addEventListener('error', () => {
      resolve(!video.error && (video.videoWidth !== 0 && video.videoHeight !== 0));
    });

    video.addEventListener('loadedmetadata', () => {
      const isLoadedCorrectly = !video.error && (video.videoWidth !== 0 && video.videoHeight !== 0);
      if (isLoadedCorrectly) {
        /** В некоторых случаях браузер может выкинуть ошибку декодинга только после начала
         * воспроизведения. Если во время воспроизведения браузер не сможет проиграть файл, он
         * выкинет error и промис зарезолвится со значением false. Если же событие
         * error не будет вызвано - значит с видео все ок и промис зарезолвится со значением true
         * через какое-то время.
         **/
        setTimeout(() => {
          video.pause();
          resolve(true);
        }, 600);
      } else {
        video.pause();
        resolve(false);
      }
    });

    video.src = source;
    video.load();
    video.play();
  });
}

export function isSessionStorageSupported() {
  try {
    if (window.sessionStorage) {
      window.sessionStorage.setItem('test', 'test');
      const sessionOK = ('test' === window.sessionStorage.getItem('test'));
      window.sessionStorage.removeItem('test');
      return sessionOK;
    }
  } catch (e) {
  }
  return false;
}

export function isLocalStorageSupported() {
  try {
    if (window.localStorage) {
      window.localStorage.setItem('test', 'test');
      const localStorageOK = ('test' === window.localStorage.getItem('test'));
      window.localStorage.removeItem('test');
      return localStorageOK;
    }
  } catch (e) {
  }
  return false;
}

export function isElementVisible(el, holder, isEntire = false) {
  if (!el || !holder) return;

  const hasVerticalScroll = (holder.scrollHeight > holder.clientHeight);
  const hasHorizontalScroll = (holder.scrollWidth > holder.clientWidth);

  const holderRect = holder.getBoundingClientRect();
  const childRect = el.getBoundingClientRect();

  if (hasVerticalScroll) {
    if (isEntire) {
      return childRect.top >= holderRect.top && childRect.bottom <= holderRect.bottom;
    }

    return childRect.top <= holderRect.top
      ? holderRect.top - childRect.top <= childRect.height
      : childRect.bottom - holderRect.bottom <= childRect.height;
  }

  if (hasHorizontalScroll) {
    if (isEntire) {
      return childRect.left >= holderRect.left && childRect.right <= holderRect.right;
    }

    return childRect.left <= holderRect.left
      ? holderRect.left - childRect.left <= childRect.width
      : childRect.right - holderRect.right <= childRect.width;
  }
}

export function getHorizontalScrollingVisibility(child, holder) {
  if (!child || !holder) return {};

  const childRect = child.getBoundingClientRect();
  const holderRect = holder.getBoundingClientRect();

  const isLeftVisible = childRect.left >= holderRect.left && childRect.left <= holderRect.right;
  const isRightVisible = childRect.right <= holderRect.right && childRect.right >= holderRect.left;

  return {
    none: !(isLeftVisible || isRightVisible),
    entire: isLeftVisible && isRightVisible,
    left: isLeftVisible,
    right: isRightVisible,
  };
}

export function getVerticalScrollingVisibility(child, holder) {
  if (!child || !holder) return {};

  const childRect = child.getBoundingClientRect();
  const holderRect = holder.getBoundingClientRect();

  const isTopVisible = childRect.top >= holderRect.top && childRect.top <= holderRect.bottom;
  const isBottomVisible = childRect.bottom <= holderRect.bottom && childRect.bottom >= holderRect.top;

  return {
    none: !(isTopVisible || isBottomVisible),
    entire: isTopVisible && isBottomVisible,
    top: isTopVisible,
    bottom: isBottomVisible,
  };
}

export async function isWebSocketSupported(timeout = 5) {
  const echo = require('./../echo').default;

  if (echo.connector.pusher.connection.state === 'connected') {
    return true;
  }

  return await new Promise(resolve => {
    let timeElapsed = 0;

    // we will try to connect to the WebSockets server
    // for timeout seconds...
    const timeoutCheck = () => {
      timeElapsed++;

      // if we can't connect in timeout seconds, assume WS server is down.
      if (timeElapsed >= timeout) {
        // thus, clear the timer and stop trying to connect.
        clearInterval(timeoutCheckTimer);
        echo.disconnect();

        resolve(false);
      }
    };

    const timeoutCheckTimer = setInterval(timeoutCheck, 1000);

    echo.connector.pusher.connection.bind('connected', () => {
      clearInterval(timeoutCheckTimer);
      resolve(true);
    });

    echo.connector.pusher.connection.bind('error', () => {
      clearInterval(timeoutCheckTimer);
      resolve(false);
    });

    echo.connector.pusher.connection.bind('state_change', states => {
      if (['unavailable', 'failed'].includes(states.current)) {
        clearInterval(timeoutCheckTimer);
        resolve(false);
      }
    });
  });
}

export async function detectBestSupportedPollingMethod() {
  const useWS = (String(process.env.MIX_CONVERSION_STATUS_POLLING_USE_WS).toLowerCase() === 'true');

  if (!useWS) {
    return DATA_JSON.commonConfig.conversion_connect_type_pc;
  }

  // if WS (with XHR fallback) has failed, fallback to PC connection
  return (await isWebSocketSupported(5))
    ? DATA_JSON.commonConfig.conversion_connect_type_ws
    : DATA_JSON.commonConfig.conversion_connect_type_pc;
}

export function isArrayEquals(array1, array2) {
  if (!array1 || !array2) return false;
  if (array1.length !== array2.length) return false;
  let i = array1.length;
  while (i--) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
}

/** @see https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pick */
export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const stringToBooleanTransformer = (v) => v.toLowerCase() === 'true';

/**
 * Returns an array of durations of all crossfades between media durations provided in the argument.
 * 'mediaDurations' is an array of floats, representing durations of media elements.
 * Crossfade duration is calculated based on these rules:
 * @see https://softos.atlassian.net/wiki/spaces/CLIDEO/pages/39256065
 */
export function getCrossfadeDurations(mediaDurations) {
  if (!Array.isArray(mediaDurations) || mediaDurations.length < 2) {
    //TODO warning to sentry
    return [0];
  }

  const minSegmentDuration = DATA_JSON.transitionsConfig.crossfade.minSegmentDuration;
  const maxTransitionDuration = DATA_JSON.transitionsConfig.crossfade.maxTransitionDuration;
  const durationDivisor = DATA_JSON.transitionsConfig.crossfade.durationDivisor;

  const crossfades = [];
  for (let i = 0; i < mediaDurations.length - 1; i++) {
    const current = mediaDurations[i];
    const next = mediaDurations[i + 1];
    const minDuration = Math.min(current, next);

    if (minDuration < minSegmentDuration) {
      continue;
    }

    let crossfadeDuration = (minDuration + minSegmentDuration) / durationDivisor;
    crossfadeDuration = Math.min(crossfadeDuration, maxTransitionDuration);

    crossfades.push(Math.round(crossfadeDuration * 1000) / 1000);
  }

  return crossfades;
}

/**
 * Returns sum of all array elements
 * @param values array of numbers
 * @returns Number
 */
export function arraySum(values) {
  return values.reduce((a, b) => a + b, 0);
}

export function isOldBrowser() {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  const result = (new UAParser(userAgent)).getResult();
  const browserOS = result.os.name.toLowerCase();
  const browserName = (result.browser.name.split(' ')[0]).toLowerCase();
  const browserVersion = parseFloat(result.browser.version);

  if (browserOS === 'kaios') {
    return true;
  }

  if (browserName === 'chrome') {
    return browserVersion < 66;
  }

  if (browserName === 'firefox') {
    return browserVersion < 57;
  }

  if (browserName === 'opera') {
    return browserVersion < 53;
  }

  if (browserName === 'safari') {
    return browserVersion < 11.3;
  }

  // Mozilla/5.0 (iPhone; CPU iPhone OS 15_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1
  // the library incorrectly detects browser as Mobile for Safari iOS
  if (browserOS === "ios" && browserName === 'mobile') {
    return browserVersion < 11.3;
  }

  if (browserName === 'edge') {
    return browserVersion < 16;
  }

  if (browserName === 'samsung') {
    return browserVersion < 9;
  }

  return false;
}
