'use strict';
//TODO Рефакторнуть это. Слишком мудрЁно

import {cleanAutoModalPayment, getEditAppIDFromURL, getFileIDFromURL, isAutoModalPayment, isAutoModalPaymentForEdit} from './billings/utils';
import startFastSpring from './billings/fastspring';
import startStripe from './billings/stripe';

$(() => {
  const planBuyButtons = $('a[data-plan], button[data-plan]');
  const modalPlans = $('#modalPlans');

  modalPlans.on('show.bs.modal', () => window.onbeforeunload = null);
  modalPlans.on('show.bs.modal', e => startFromFile($(e.relatedTarget).data('redirect-id')));
  modalPlans.on('show.bs.modal', e => enableEditVideo($(e.relatedTarget).data('edit-id'), $(e.relatedTarget).data('edit-app-id')));
  modalPlans.on('show.bs.modal', e => enableEditPayment($(e.relatedTarget).data('edit-id'), $(e.relatedTarget).data('edit-app-id')));
  modalPlans.on('show.bs.modal', () => enableRedirectURL(modalPlans.data('redirect-url')));
  modalPlans.on('hide.bs.modal', () => disableEditPayment());
  modalPlans.on('hide.bs.modal', () => disableRedirectURL());
  modalPlans.on('hide.bs.modal', () => cleanAutoModalPayment()); //TODO Check influence

  if (modalPlans.hasClass('show')) {
    modalPlans.trigger('show.bs.modal');
  }

  planBuyButtons.click(function () {
    const plan = $(this).data('plan');
    const testOrder = (typeof $(this).data('test') !== 'undefined');
    const fileID = (typeof $(this).data('file-id') !== 'undefined') ? $(this).data('file-id') : 0;
    const editID = (typeof $(this).data('edit-id') !== 'undefined') ? $(this).data('edit-id') : 0;
    const appID = (typeof $(this).data('edit-app-id') !== 'undefined') ? $(this).data('edit-app-id') : '';
    const redirectURL = (typeof $(this).data('redirect-url') !== 'undefined') ? $(this).data('redirect-url') : '';

    if (editID > 0) {
      sessionStorage.setItem('edit-id', editID.toString());
    } else {
      sessionStorage.removeItem('edit-id');
    }

    if (appID !== '') {
      sessionStorage.setItem('edit-app-id', appID.toString());
    } else {
      sessionStorage.removeItem('edit-app-id');
    }

    if (redirectURL !== '') {
      sessionStorage.setItem('redirect-url', redirectURL.toString());
    } else {
      sessionStorage.removeItem('redirect-url');
    }

    sessionStorage.setItem('editorFlow.isInsideIframe', window.top.location.href !== window.location.href ? 'yes' : 'no');

    const projectKey = (new URLSearchParams(window.location.search.substring(1))).get('projectId');
    if (projectKey !== null) {
      sessionStorage.setItem('editorFlow.projectKey', projectKey);
    }

    const isResultPage = (new URLSearchParams(window.location.search.substring(1))).get('isResultPage');
    if (isResultPage !== null) {
      sessionStorage.setItem('editorFlow.isResultPage', isResultPage);
    }

    ClickPlan(plan, fileID, testOrder);
  });


  let planClicked = false;
  $('[data-plan]').each((i, el) => {
    const plan = $(el).data('plan');
    if (isAutoModalPayment(plan)) {
      planClicked = true;
      $(el).click();
    }
  });

  if (!planClicked) {
    if (isAutoModalPayment()) {
      startFromFile(getFileIDFromURL());
      enableEditVideo(undefined);
      modalPlans.modal({backdrop: false});
    } else if (isAutoModalPaymentForEdit()) {
      startFromFile(undefined);
      enableEditVideo(getFileIDFromURL(), getEditAppIDFromURL());
      modalPlans.modal({backdrop: false});
    }
  }


  function startPaymentByURLToken() {
    const loc = window.location.toString();
    const reg1 = /\/(sub_[a-z0-9]+)/;
    if (reg1.test(loc)) {
      const type = reg1.exec(loc)[1];
      planBuyButtons.filter(`[data-plan=${type}]`).click();
    }
  }

  setTimeout(startPaymentByURLToken, 1000);

  function startFromFile(fileID) {
    //TODO Переделать: если идём через isAutoModalPayment, то startFromFile вызывается дважды, второй раз с fileID = undefined
    if (isAutoModalPayment() && (fileID === undefined) && (getFileIDFromURL() !== undefined)) fileID = getFileIDFromURL();
    if (fileID !== undefined) {
      $('[data-file]').each(function () {
        let text = $(this).data('file');
        text = text.replace(/:br/g, '<br>');
        $(this).html(text);
      });
    } else {
      $('[data-no-file]').each(function () {
        let text = $(this).data('no-file');
        text = text.replace(/:br/g, '<br>');
        $(this).html(text);
      });
    }
  }

  function ClickPlan(plan, fileID, testOrder) {
    $.post('/payments/config', (data) => {
      if (data?.billing === 'ST') {
        const planButton = planBuyButtons.filter(`[data-plan=${plan}]`);
        planButton.attr('disabled', true);
        startStripe(plan, fileID, testOrder).fail(() => {
          planButton.attr('disabled', false);
          startFastSpring(plan, fileID, testOrder);
        });
      } else {
        startFastSpring(plan, fileID, testOrder);
      }
    }, 'json').fail(() => {
      startFastSpring(plan, fileID, testOrder);
    });
  }

  //modalPlans modification
  function enableEditVideo(fileID, appID) {
    //TODO Переделать: если идём через isAutoModalPayment, то enableEditVideo вызывается дважды, второй раз с fileID = undefined
    if (isAutoModalPaymentForEdit() && (fileID === undefined) && (getFileIDFromURL() !== undefined)) fileID = getFileIDFromURL();
    if (isAutoModalPaymentForEdit() && (appID === undefined) && (getEditAppIDFromURL() !== undefined)) appID = getFileIDFromURL();

    if (fileID !== undefined) {
      $('[data-edit-media]').each(function () {
        let text = $(this).data('edit-media');
        text = text.replace(/:br/g, '<br>');
        $(this).html(text);
      });

      planBuyButtons.attr('data-edit-id', fileID);
    }

    if (appID !== undefined) {
      planBuyButtons.attr('data-edit-app-id', appID);
    }
  }

  function enableEditPayment(fileID, appID) {
    //TODO Переделать: если идём через isAutoModalPayment, то enableEditPayment вызывается дважды, второй раз с fileID = undefined
    if (isAutoModalPaymentForEdit() && (fileID === undefined) && (getFileIDFromURL() !== undefined)) fileID = getFileIDFromURL();
    if (isAutoModalPaymentForEdit() && (appID === undefined) && (getEditAppIDFromURL() !== undefined)) appID = getEditAppIDFromURL();

    if (fileID !== undefined) {
      planBuyButtons.attr('data-edit-id', fileID);
    }

    if (appID !== undefined) {
      planBuyButtons.attr('data-edit-app-id', appID);
    }
  }

  function disableEditPayment() {
    planBuyButtons.removeData('edit-id');
    planBuyButtons.removeData('edit-app-id');
    planBuyButtons.removeAttr('data-edit-id');
    planBuyButtons.removeAttr('data-edit-app-id');
  }

  function enableRedirectURL(redirectURL) {
    if (redirectURL !== undefined) {
      planBuyButtons.attr('data-redirect-url', redirectURL);
    } else if (sessionStorage.getItem('payment-redirect-url')) {
      planBuyButtons.attr('data-redirect-url', sessionStorage.getItem('payment-redirect-url'));
      sessionStorage.removeItem('payment-redirect-url');
    }
  }

  function disableRedirectURL() {
    modalPlans.removeData('redirect-url');
    planBuyButtons.removeData('redirect-url');
    planBuyButtons.removeAttr('data-redirect-url');
  }

//modalPlans modification
});
