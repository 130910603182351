import axios from 'axios';

import config from '../../config';
import dataJson from './../../data.json';

const state = {
  account: {
    id: undefined,
    name: '',
    email: '',
    member: false,
    input_profile_email: '',
    plan_is_subscription: true,
    plan: '',
    plan_rebills_active: false,
    projects: [], // N.B.: This is EXPORT RESULTS and not a Projects. See comment in resources/client/js/account/pages/AccountProjects.vue
    editor_projects: [],
    social_accounts: [],
    plan_name_localized: '',
    plan_bill_last_date: '',
    plan_end_date: '',
    plan_bill_next_date: '',
    plan_price: '',
    plan_billing: '',
  },
  config: {
    limits: {
      maxFileSizeVideo: 0,
      maxFileSizeImage: 0,
      maxFileSizeAudio: 0,
    },
  },
  isUserLoaded: false,
};

// getters
const getters = {
  account: (state) => state.account,
  hasAccount: (state) => state.account.id !== undefined,
  isAccountHasNonRenewablePlan: (state) => state.account.member && !state.account.plan_is_subscription,
  accountProjects: (state) => state.account.projects,
  accountProjectsVisible: (state) => state.account.projects.filter(
    x => [
      config.CONVERSION_STATUS_PROCESSING,
      config.CONVERSION_STATUS_PROCESSED,
      config.CONVERSION_STATUS_ERROR,
    ].includes(x.public_status)
  ),
  accountProjectsProcessing: (state, getters) => getters.accountProjects.filter(x => x.public_status === config.CONVERSION_STATUS_PROCESSING),
  accountProjectsRemovingWatermark: (state, getters) => getters.accountProjects.filter(x => x.is_removing_watermark),
  currentConfig: (state) => state.config,
  editorProjects: (state) => state.account.editor_projects,
  isUserLoaded: (state) => state.isUserLoaded,
};

// actions
const actions = {
  loadAuthUser({commit}, {fetchProjects = false} = {}) {
    const params = {
      ...(fetchProjects && {fetchProjects: 1}), // add "fetchProjects=1" only if fetchProjects is true
    };

    commit('setLimits');

    return axios
      .get('/api/client', {
        params: params,
      })
      .then(r => r.data)
      .then(user => {
        if (Object.keys(user).length) {
          commit('setAccount', user);
          commit('setLimits');
        }
        commit('setUserIsLoaded');

        return user;
      });
  },

  accountProfileNameUpdate({commit}, name) {
    return axios.put('/api/profile/update', {name})
      .then(() => {
        commit('setAccountName', name);
      });
  },

  accountProfileEmailUpdate({commit}, email) {
    return axios.put('/api/profile/update', {email})
      .then(() => {
        commit('setAccountEmail', email);
      });
  },
};

// mutations
const mutations = {
  setAccount(state, account) {
    state.account = {...state.account, ...account};
  },

  setAccountName(state, name) {
    state.account.name = name;
  },

  setAccountEmail(state, email) {
    state.account.email = email;
  },

  setSocialAccounts(state, socialAccountsList) {
    state.account.socialAccounts = socialAccountsList;
  },

  storeAccountProject(state, project) {
    state.account.projects.push(project);
  },

  /**
   * @todo
   * Variant A: state.account.projects = [...state.account.projects];
   * Variant B: this._vm.$set(state.account.projects, payload.index, payload.project);
   *
   * Variant A (current function) is very slow on a large number of projects.
   * Variant B works much faster, but it uses undocumented VueX API that is absent in the Vuex 4. ¯\_(ツ)_/¯
   */
  updateAccountProject(state, payload) {
    state.account.projects[payload.index] = payload.project;
    state.account.projects = [...state.account.projects];
  },

  removeAccountProject(state, index) {
    state.account.projects.splice(index, 1);
  },

  removeAccountAllProjects(state) {
    state.account.projects = [];
  },

  setLimits(state) {
    const isPlanExists = Object.keys(dataJson.limitsConfig).includes(state.account.plan);

    state.config.limits = dataJson.limitsConfig[isPlanExists ? state.account.plan : 'V'];
  },

  storeAccountEditorProject(state, project) {
    state.account.editor_projects.push(project);
  },

  updateAccountEditorProject(state, payload) {
    state.account.editor_projects[payload.index] = payload.project;
    state.account.editor_projects = [...state.account.editor_projects];
  },

  removeAccountEditorProject(state, index) {
    state.account.editor_projects.splice(index, 1);
  },

  removeAccountEditorAllProjects(state) {
    state.account.editor_projects = [];
  },

  setUserIsLoaded(state) {
    state.isUserLoaded = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
