import {getRedirectUrl} from './utils';

export default function startStripe(PlanID, fileID, testPayment) {
  const defaultRedirectUrl = '/account/plan';
  const redirectUrlSuccess = getRedirectUrl(defaultRedirectUrl);
  const redirectUrlCancel = window.top.location.href;

  return $.post('/payments/stripe/checkout', {
    plan_id: PlanID,
    return_url_success: redirectUrlSuccess,
    return_url_cancel: redirectUrlCancel,
  }, (stripeCheckoutUrl) => {
    if (stripeCheckoutUrl.length) {
      window.top.location = stripeCheckoutUrl;
    }
  }, 'json');
}
