import Vue from 'vue';
import VueI18n from 'vue-i18n';
import $ from 'jquery';
import {
  WidgetCommonErrorEn,
  WidgetLoginViaEmailEn,
  WidgetLoginViaSocialNetworksEn,
  WidgetCommonErrorRu,
  WidgetLoginViaEmailRu,
  WidgetLoginViaSocialNetworksRu,
  WidgetCommonErrorDe,
  WidgetLoginViaEmailDe,
  WidgetLoginViaSocialNetworksDe,
  WidgetCommonErrorEs,
  WidgetLoginViaEmailEs,
  WidgetLoginViaSocialNetworksEs,
  WidgetCommonErrorFr,
  WidgetLoginViaEmailFr,
  WidgetLoginViaSocialNetworksFr,
  WidgetCommonErrorIt,
  WidgetLoginViaEmailIt,
  WidgetLoginViaSocialNetworksIt,
  WidgetCommonErrorPt,
  WidgetLoginViaEmailPt,
  WidgetLoginViaSocialNetworksPt,
  WidgetSocialLoginErrorEn,
  WidgetSocialLoginErrorRu,
  WidgetSocialLoginErrorDe,
  WidgetSocialLoginErrorEs,
  WidgetSocialLoginErrorFr,
  WidgetSocialLoginErrorIt,
  WidgetSocialLoginErrorPt,
  WidgetSocialNetworkAttachErrorEn,
  WidgetSocialNetworkAttachErrorDe,
  WidgetSocialNetworkAttachErrorRu,
  WidgetSocialNetworkAttachErrorEs,
  WidgetSocialNetworkAttachErrorFr,
  WidgetSocialNetworkAttachErrorIt,
  WidgetSocialNetworkAttachErrorPt,
  WidgetFacebookNotificationEn,
  WidgetFacebookNotificationRu,
  WidgetFacebookNotificationDe,
  WidgetFacebookNotificationEs,
  WidgetFacebookNotificationFr,
  WidgetFacebookNotificationIt,
  WidgetFacebookNotificationPt,
} from '#@clideo/ui-kit/dist/server/localization';

Vue.use(VueI18n);

let locale = $('html').attr('lang');
locale = (typeof locale !== 'undefined') ? locale : 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: locale, // set locale
  messages: {
    en: {
      ...require('./locales/en.json'),
      ...WidgetLoginViaEmailEn,
      ...WidgetCommonErrorEn,
      ...WidgetFacebookNotificationEn,
      ...WidgetLoginViaSocialNetworksEn,
      ...WidgetSocialLoginErrorEn,
      ...WidgetSocialNetworkAttachErrorEn,
    },
    ru: {
      ...require('./locales/ru.json'),
      ...WidgetLoginViaEmailRu,
      ...WidgetCommonErrorRu,
      ...WidgetFacebookNotificationRu,
      ...WidgetLoginViaSocialNetworksRu,
      ...WidgetSocialLoginErrorRu,
      ...WidgetSocialNetworkAttachErrorRu,
    },
    de: {
      ...require('./locales/de.json'),
      ...WidgetLoginViaEmailDe,
      ...WidgetCommonErrorDe,
      ...WidgetFacebookNotificationDe,
      ...WidgetLoginViaSocialNetworksDe,
      ...WidgetSocialLoginErrorDe,
      ...WidgetSocialNetworkAttachErrorDe,
    },
    es: {
      ...require('./locales/es.json'),
      ...WidgetLoginViaEmailEs,
      ...WidgetCommonErrorEs,
      ...WidgetFacebookNotificationEs,
      ...WidgetLoginViaSocialNetworksEs,
      ...WidgetSocialLoginErrorEs,
      ...WidgetSocialNetworkAttachErrorEs,
    },
    fr: {
      ...require('./locales/fr.json'),
      ...WidgetLoginViaEmailFr,
      ...WidgetCommonErrorFr,
      ...WidgetFacebookNotificationFr,
      ...WidgetLoginViaSocialNetworksFr,
      ...WidgetSocialLoginErrorFr,
      ...WidgetSocialNetworkAttachErrorFr,
    },
    it: {
      ...require('./locales/it.json'),
      ...WidgetLoginViaEmailIt,
      ...WidgetCommonErrorIt,
      ...WidgetFacebookNotificationIt,
      ...WidgetLoginViaSocialNetworksIt,
      ...WidgetSocialLoginErrorIt,
      ...WidgetSocialNetworkAttachErrorIt,
    },
    pt: {
      ...require('./locales/pt.json'),
      ...WidgetLoginViaEmailPt,
      ...WidgetCommonErrorPt,
      ...WidgetFacebookNotificationPt,
      ...WidgetLoginViaSocialNetworksPt,
      ...WidgetSocialLoginErrorPt,
      ...WidgetSocialNetworkAttachErrorPt,
    },
  },
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    },
  },
});

window.i18n = i18n;
export default i18n;
