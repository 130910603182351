import {getCLS, getFCP, getFID, getLCP, getTTFB} from 'web-vitals';

function sendToGoogleAnalytics({name, delta, id}) {
  if (typeof window.gtag === 'function') {
    window.gtag('event', name, {
      event_category: 'Web Vitals',
      event_label: id,
      non_interaction: true,
      value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    });
  } else if (typeof window.dataLayer === 'object') {
    window.dataLayer.push({
      event: 'web-vitals',
      event_category: 'Web Vitals',
      event_action: name,
      event_label: id,
      event_value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    });
  }
}

getCLS(sendToGoogleAnalytics);
getFCP(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);
getTTFB((metric) => {
  metric.delta = metric.value - metric.entries[0].requestStart;
  sendToGoogleAnalytics(metric);
});
