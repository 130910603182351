import Vue from 'vue';
import axios from 'axios';

import i18n from './../../i18n';
import {
  detectBestSupportedPollingMethod,
  pick,
} from '../../libs/Helpers';
import config from '../../config';
import WS from '../../libs/WS';
import DATA_JSON from '../../data.json';

const STATUS_NONE = 0;
const STATUS_SUCCESS = 1;
const STATUS_ERROR = 2;

const TYPE_VIDEO = 0;
const TYPE_IMAGE = 1;
const TYPE_AUDIO = 2;

const state = {
  params: {
    zoom: 1,
    mute: false,
    aspect: 2, // TODO: Not-value, this is ID of array (rename to aspectIdentifier)
    duration: 8, // TODO: Default ID (not real time) for image playing time (rename to imageDurationIdentifier)
    speed: 0.5,
    rate: 0.2,
    reverse: false,
    loop: false,
    loopCount: 2,
    aspectRatio: '1:1',

    audioVolume: 100,
    videoVolume: 100,

    leftBorderTime: 0,
    rightBorderTime: 1,

    isAudioLoop: false,
  },

  inform: {
    runConvertImmediately: false,
  },

  editorState: { },
  messages: [],

  isStartProcessing: false,
  progressInPercent: 0,
  isAvailableProgressInPercent: true,
  processType: undefined,
  emulatedProgressTimerId: undefined,

  errors: [],
  status: 0,

  resultFile: {
    location: null,
    filename: null,
    poster: null,
    size: null,
    file_type: null,
    redirect: false,
    redirectLocation: false,
  },

  resultProjectId: 0,

  files: [],
  filesLoadedAtTimestamp: 0,

  stream: [
    [],
  ],

  player: {
    position: 0,
    playing: false,
    time: 0,
    duration: 2,
    type: TYPE_VIDEO,
    muted: false,
  },
};

const DEFAULT_VIDEO_EL = {
  location: null,
  filename: null,
  poster: null,
  size: null,
  duration: 0,
  frames: [],
  metadata: {
    height: 0,
    width: 0,
  },
  extension: 'mp4',
  mimeType: 'video/mp4',
  type: TYPE_VIDEO,
};

const DEFAULT_AUDIO_EL = {
  location: null,
  filename: null,
  poster: null,
  size: null,
  duration: 0,
  frames: [],
  mimeType: 'audio/mp3',
  type: TYPE_AUDIO,
};

const converterResponseWSHandler = (commit, statId, sequenceId) => {
  // noinspection JSUnresolvedVariable
  return new Promise(resolve => {
    const eventConversionStatusUpdated = (e, webSocket) => {
      // NOTE: If response not for current conversion (but sequenceId same for all conversions)
      if (statId !== e.file.id) {
        return;
      }

      if (e.file.public_status === config.CONVERSION_STATUS_PROCESSING) {
        commit('setProgressInPercent', Math.min(Math.max(1, e.progressInPercent), 99));
      }

      if (e.file.public_status === config.CONVERSION_STATUS_CREATED) {
        commit('setProgressInPercent', Math.min(Math.max(1, e.progressInPercent), 99));
      }

      // noinspection JSUnresolvedVariable
      if (e.file.public_status === config.CONVERSION_STATUS_ERROR) {
        resolve('conversionResult' in e ? e.conversionResult : null);
      }

      // noinspection JSUnresolvedVariable
      if (e.file.public_status === config.CONVERSION_STATUS_PROCESSED) {
        webSocket.leave();

        // noinspection JSUnresolvedVariable
        resolve(e.conversionResult);
      }
    };

    const webSocket = new WS(`notifications.conversions@sequence_${sequenceId}`);
    webSocket.bind('subscription_error', () => resolve(null));
    webSocket.bind('Conversion\\ConversionStatusUpdated', eventConversionStatusUpdated);
    webSocket.listen();
  });
};

const converterResponseThenHandler = (commit, dispatch, resultFile) => {
  commit('setIsProgressInPercentAvailable', false);

  const possibleError = new Error(
    (resultFile && 'message' in resultFile && resultFile.message !== null && resultFile.message.length)
      ? resultFile.message
      : i18n.t('errors.something-went-wrong')
  );

  if (resultFile && 'redirectLocation' in resultFile && resultFile.redirectLocation !== null && resultFile.redirectLocation.length) {
    dispatch('setErrorWithRedirect', {
      error: possibleError,
      destination: resultFile.redirectLocation,
    });
    return null;
  }

  if (!(resultFile && 'public_result_file' in resultFile && resultFile.public_result_file.length)) {
    throw possibleError;
  }

  return resultFile;
};

const converterResponseCatchHandler = (commit, dispatch, e, redirectBackOnError) => {
  const connectionError = i18n.t('errors.browser-dropped-connection').replace(/\*([^*]+?)\*/g, '<a href="/profile" target="blank"><b>$1</b></a>');

  if (redirectBackOnError) {
    let error;

    if (typeof e.message === 'string' && e.message === 'Network Error') {
      // Alternative solution: if (!e.response.status)
      // @see https://github.com/axios/axios/issues/383#issuecomment-234079506

      error = new Error(connectionError);
    } else if (e.response && e.response.status === 499) {
      error = new Error(connectionError);
    } else if (e.response && (e.response.status === 422 || e.response.status === 500)) {
      // noinspection JSCheckFunctionSignatures
      error = new Error(i18n.t('errors.something-went-wrong'));
    } else {
      error = e;
    }

    dispatch('setErrorWithRedirect', {
      error,
      destination: document.referrer || window.top.location.href.replace('/editor/', '/'),
    });

    return;
  }

  let errors = [];
  let generalError = '';

  try {
    generalError = i18n.t('errors.something-went-wrong');

    if (typeof e.message === 'string' && e.message === 'Network Error') {
      // Alternative solution: if (!e.response.status)
      // @see https://github.com/axios/axios/issues/383#issuecomment-234079506

      errors.push(connectionError);
    } else if (e.response && e.response.status === 499) {
      errors.push(connectionError);
    } else if (e.response && (e.response.data.status === 422 || e.response.data.status === 500)) {
      errors.push(e.response.data.message);
      errors = [...e.response.data.error]; // that is strange, since we don't have error key in response
    } else {
      errors.push(e.response.data.message || generalError);
    }
  } catch (innerError) {
    //We need to robustly stop conversion process even if something gone wrong in try block above
    //Previously, if client's browser close the connection code above fails silently and setIsStartProcessing was true forever

    errors.push(generalError);
  }

  commit('setIsStartProcessing', false);
  commit('setStatus', STATUS_ERROR);
  commit('setErrors', errors);

  // returning e is pointless, since .catch block in client code will never fire. so we will throw it instead
  throw e;
};

// getters
const getters = {
  params: (state) => state.params,
  inform: (state) => state.inform,
  isStartProcessing: (state) => state.isStartProcessing,
  editorState: (state) => state.editorState,
  messages: (state) => state.messages,
  errors: (state) => state.errors,
  status: (state) => state.status,
  resultFile: (state) => state.resultFile,
  resultProjectId: (state) => state.resultProjectId,
  files: (state) => state.files,
  videos: (state) => state.files.filter(x => x.type === TYPE_VIDEO),
  firstVideo: (state, getters) => (getters.videos.length > 0) ? getters.videos[0] : DEFAULT_VIDEO_EL,
  firstVideoOrImage: (state, getters) => (getters.videosAndImages.length > 0) ? getters.videosAndImages[0] : DEFAULT_VIDEO_EL,
  firstAudio: (state, getters) => (getters.audios.length > 0) ? getters.audios[0] : DEFAULT_AUDIO_EL,
  videosAndImages: (state, getters) => getters.files.filter(x => x.type === TYPE_VIDEO || x.type === TYPE_IMAGE),
  images: (state) => state.files.filter(x => x.type === TYPE_IMAGE),
  audios: (state) => state.files.filter(x => x.type === TYPE_AUDIO),
  player: (state) => state.player,
  stream: (state) => (id) => (typeof state.stream[id] !== 'undefined') ? state.stream[id] : [],
  streamAudios: (state, getters) => (id) => (getters.stream(id).length > 0) ? getters.stream(id).filter(x => x.type === TYPE_AUDIO) : [],
  streamFirstAudio: (state, getters) => (id) => (getters.streamAudios(id).length > 0) ? getters.streamAudios(id)[0] : DEFAULT_AUDIO_EL,
  isAvailableProgressInPercent: (state) => state.isAvailableProgressInPercent,
  progressInPercent: (state) => state.progressInPercent,
  processType: (state) => state.processType,
  emulatedProgressTimerId: (state) => state.emulatedProgressTimerId,

  /**
   * Returns if files were loaded more than 120 seconds ago.
   */
  areFilesNotRecentlyLoaded: (state) => Math.floor(Date.now() / 1000) - state.filesLoadedAtTimestamp > 120,
};

const mutations = {
  setParams(state, params) {
    state.params = {...state.params, ...params};
  },

  setInform(state, inform) {
    state.inform = {...state.inform, ...inform};
  },

  setEditorState(state, editorState) {
    state.editorState = {...state.editorState, ...editorState};
  },

  setIsStartProcessing(state, progress = false) {
    state.isStartProcessing = progress;
  },

  setProgressInPercent(state, percent) {
    state.progressInPercent = percent;
  },

  setIsProgressInPercentAvailable(state, isAvailable = false) {
    state.isAvailableProgressInPercent = isAvailable;
  },

  setProcessType(state, type) {
    state.processType = type;
  },

  setEmulatedProgressTimerId(state, timerId) {
    state.emulatedProgressTimerId = timerId;
  },

  setErrors(state, errors = []) {
    state.errors = errors;
  },

  setMessages(state, messages = []) {
    state.messages = messages;
  },

  setStatus(state, status = 0) {
    state.status = status;
  },

  setResultFile(state, file) {
    state.resultFile = file;
  },

  setResultProjectId(state, id) {
    state.resultProjectId = id;
  },

  storeFiles(state, files) {
    state.files = files;
    state.filesLoadedAtTimestamp = Math.floor(Date.now() / 1000);
  },

  storeStream(state, stream) {
    state.stream = stream;
  },

  setFile(state, file) {
    state.files.push(file);
  },

  // BEGIN for player
  setPlayer(state, player) {
    state.player = {...state.player, ...player};
  },

  setPlayerStatus(state, status = false) {
    state.player.playing = status;
  },

  setPlayerTime(state, time) {
    state.player.time = time;
  },

  setPlayerMuted(state, muted) {
    state.player.muted = muted;
  },

  setPlayerDuration(state, time) {
    state.player.duration = time;
  },
  // END for player

  clearFiles(state) {
    state.files = [];
  },

  removeFile(state, index) {
    state.files.splice(index, 1);
  },

  removeFileFromStream(state, stream, index) {
    state.stream[stream].splice(index, 1);
  },

  setVideoError(state, index) {
    const media = state.files[index];
    Vue.set(state.files, index, {
      ...media,
      ...{
        error: true,
      },
    });
  },

  closeVideoError(state, index) {
    const media = state.files[index];
    Vue.set(state.files, index, {
      ...media,
      ...{
        errorVideoPopup: false,
      },
    });
  },
};

const actions = {
  storeParams({commit}, params) {
    commit('setParams', params);
  },

  storeParamsForStream({commit}, params) {
    commit('setParamsForStream', params);
  },

  storeInform({commit}, inform) {
    commit('setInform', inform);
  },

  storeEditorState({commit}, editorState) {
    commit('setEditorState', editorState);
  },

  setIsStartProcessing({commit}, status) {
    commit('setIsStartProcessing', status);
  },

  setProgressInPercent({commit}, percent) {
    commit('setProgressInPercent', percent);
  },

  setProcessType({commit}, type) {
    commit('setProcessType', type);
  },

  startEmulatedProcess({commit, getters}, processType) {
    commit('setIsStartProcessing', true);
    commit('setProcessType', processType);

    const timerId = setInterval(() => {
      commit('setProgressInPercent', getters.progressInPercent + 1);

      if (getters.progressInPercent >= 95) {
        clearInterval(getters.emulatedProgressTimerId);
      }
    }, 1500);

    commit('setEmulatedProgressTimerId', timerId);
  },

  stopEmulatedProcess({commit, getters}) {
    clearInterval(getters.emulatedProgressTimerId);
    commit('setEmulatedProgressTimerId', undefined);
  },

  resetEmulatedProcess({commit}) {
    commit('setIsStartProcessing', false);
    commit('setProcessType', undefined);
    commit('setProgressInPercent', 0);
  },

  clearProgress({commit}) {
    commit('setErrors', []);
    commit('setStatus', STATUS_NONE);
    commit('setIsStartProcessing', false);
    commit('setProcessType', undefined);
    commit('setResultFile', {
      cleared: true,
      location: null,
      filename: null,
      size: null,
    });
  },

  clearParams({commit}) {
    commit('setParams', { });
  },

  fetchParams({commit}) {
    return axios.get('/api/params')
      .then((response) => {
        commit('setParams', response.data);
        return response;
      });
  },

  fetchFiles({commit, dispatch}) {
    return axios.get('/api/files')
      .then((response) => {
        const files = response.data.map((x) => ({
          ...x,
          ...{
            error: false,
            errorVideoPopup: true,
            image: x.poster,
            duration: x.duration ? parseFloat(x.duration) : 0,
          },
        }));

        commit('storeFiles', files);
        return files;
      })
      .then((response) => {
        dispatch('storeStreams', response);
        return response;
      })
      .catch(e => {
        const errors = [];

        if (e.response && e.response.status === 422) {
          errors.push(e.response.data.error);
        } else if (e.response) {
          errors.push(e.response.data.message);
        }

        commit('setIsStartProcessing', false);
        commit('setStatus', STATUS_ERROR);
        commit('setErrors', errors);

        throw e;
      });
  },

  /**
   * If files loaded some time ago - reload them.
   * It is for a case when there's an error playing a file as it could be moved to a different storage, so we need to reload files to refresh URLs.
   * If files were recently loaded, then treat the file as unavailble and mark it as having an error.
   */
  setVideoErrorOrReloadFiles({commit, dispatch, getters}, index) {
    if (getters.areFilesNotRecentlyLoaded) {
      dispatch('fetchFiles');
    } else {
      commit('setVideoError', index);
    }
  },

  storeStreams({commit}, files) {
    commit('storeStream', [
      files.filter(x => x.type === TYPE_VIDEO || x.type === TYPE_IMAGE),
      files.filter(x => x.type === TYPE_AUDIO),
    ]);
  },

  setPlayer({commit}, player) {
    commit('setPlayer', player);
  },

  updateFiles({commit}, files) {
    return commit('storeFiles', files);
  },

  /**
   * Merge video
   * TODO: Will use same for merge audio
   */
  mergeReorderFilesRequest({commit}, files) {
    return axios.post('/api/files/reorder', {files: files})
      .catch(e => {
        const errors = [];

        if (e.response && e.response.status === 422) {
          errors.push(e.response.data.error);
        } else if (e.response) {
          errors.push(e.response.data.message);
        }

        commit('setIsStartProcessing', false);
        commit('setStatus', STATUS_ERROR);
        commit('setErrors', errors);

        return e;
      });
  },

  /**
   * TODO: Remove after rebuild merge audio tool
   */
  reorderFiles({commit, dispatch}, files) {
    return axios.post('/api/files/reorder', {files: files})
      .then((response) => {
        commit('storeFiles', response.data);
        return response.data;
      })
      .then((response) => {
        dispatch('storeStreams', response.data);
        return response;
      })
      .catch(e => {
        const errors = [];

        if (e.response && e.response.status === 422) {
          errors.push(e.response.data.error);
        } else if (e.response) {
          errors.push(e.response.data.message);
        }

        commit('setIsStartProcessing', false);
        commit('setStatus', STATUS_ERROR);
        commit('setErrors', errors);

        return e;
      });
  },

  setFile({commit}, file) {
    commit('setFile', file);
  },

  setErrors({commit}, errors, status = false) {
    commit('setIsStartProcessing', status);
    commit('setStatus', STATUS_ERROR);
    commit('setErrors', errors);
  },

  setErrorWithRedirect(context, payload) {
    let errors;

    try {
      errors = JSON.parse(sessionStorage.getItem('lastErrors')) || [];
    } catch (e) {
      errors = [];
    }

    if (payload.error instanceof Error) {
      errors.push({
        code: 0,
        message: payload.error.message,
      });
    } else {
      errors.push({
        code: payload.error.response.status || 400,
        message: payload.error.response.data.message || i18n.t('errors.something-went-wrong'),
      });
    }

    // Remove warning for leave and refresh
    window.onbeforeunload = null;

    sessionStorage.setItem('lastErrors', JSON.stringify(errors));
    window.top.location = payload.destination;
  },

  setMessages({commit}, messages, status = false) {
    commit('setIsStartProcessing', status);
    commit('setStatus', STATUS_SUCCESS);
    commit('setMessages', messages);
  },

  clearFiles({commit}) {
    commit('clearFiles');
  },

  async joinToConvertProcessing({commit, dispatch}, statId, redirectBackOnError = false) {
    commit('setIsStartProcessing', true);
    commit('setProgressInPercent', 0); // Before connect to WS

    const pollingMethod = await detectBestSupportedPollingMethod();

    if (pollingMethod !== DATA_JSON.commonConfig.conversion_connect_type_ws) {
      // TODO: Fatal error
      return;
    }

    commit('setProgressInPercent', 1); // After connect to WS

    commit('setIsProgressInPercentAvailable', pollingMethod === DATA_JSON.commonConfig.conversion_connect_type_ws);

    return axios.post('/api/converter/processing', {statId})
      .then(async response => {
        if (response === undefined) {
          throw {
            response: {
              status: 499,
            },
          };
        }

        if (response.data?.response?.project?.id) {
          commit('setResultProjectId', response.data.response.project.id);
        }

        return await converterResponseWSHandler(commit, response.data.response.stat.id, response.data.response.stat.sequence_id);
      })
      .then(resultFile => converterResponseThenHandler(commit, dispatch, resultFile))
      .catch(e => converterResponseCatchHandler(commit, dispatch, e, redirectBackOnError));
  },

  async convert({state, commit, dispatch}, redirectBackOnError = false) {
    commit('setIsStartProcessing', true);
    commit('setProgressInPercent', 0); // Before connect to WS

    const pollingMethod = await detectBestSupportedPollingMethod();
    commit('setIsProgressInPercentAvailable', pollingMethod === DATA_JSON.commonConfig.conversion_connect_type_ws);

    commit('setProgressInPercent', 1); // After connect to WS

    const toolRequiredParams = state.params.type in config.TOOL_REQUIRED_PARAMS
      ? pick(state.params, [...config.GLOBAL_WHITELISTED_PARAMS, ...config.TOOL_REQUIRED_PARAMS[state.params.type]])
      : {};

    const params = {
      pollingMethod,
      ...{type: state.params.type},
      ...toolRequiredParams,
    };

    const handlers = {
      [DATA_JSON.commonConfig.conversion_connect_type_pc]: async response => response.data.response,
      [DATA_JSON.commonConfig.conversion_connect_type_ws]: async response => await converterResponseWSHandler(commit, response.data.response.stat.id, response.data.response.stat.sequence_id),
    };

    return axios.post('/api/converter/convert', params)
      .then(async response => {
        if (response === undefined) {
          throw {
            response: {
              status: 499,
            },
          };
        }

        if (response.data.pollingMethod === DATA_JSON.commonConfig.conversion_connect_type_ws) {
          const statId = response.data.response.stat.id;

          const currentURL = new URL(window.location.href);
          const searchParams = currentURL.searchParams;
          searchParams.set('statProcessingId', statId);
          currentURL.search = searchParams.toString();

          window.history.replaceState(
            {previousURL: window.location.href},
            null,
            currentURL.toString()
          );
        }

        if (response.data?.response?.project?.id) {
          commit('setResultProjectId', response.data.response.project.id);
        }

        return await handlers[response.data.pollingMethod](response);
      })
      .then(resultFile => converterResponseThenHandler(commit, dispatch, resultFile))
      .catch(e => converterResponseCatchHandler(commit, dispatch, e, redirectBackOnError));
  },

  clearErrors({commit}) {
    commit('setErrors', []);
  },

  clearMessages({commit}) {
    commit('setMessages', []);
  },

  async translateSubtitles({state, commit}, targetLanguage) {
    const subtitles = state.editorState.subtitles.map((subtitle) => {
      return {
        text: subtitle.text,
        id: subtitle.uuid,
      };
    });

    return axios.post('/api/subtitles-translate', {
      subtitles,
      targetLanguage,
    }).then((response) => {
      const newSubtitles = state.editorState.subtitles.map((subtitle) => {
        const translatedSubtitle = response.data.subtitles.find((translated) => {
          return translated.id === subtitle.uuid;
        });

        return {
          ...subtitle,
          text: translatedSubtitle.text,
        };
      });

      commit('setEditorState', {
        subtitles: newSubtitles,
      });
    }).catch((error) => {
      commit('setIsStartProcessing', false);
      commit('setStatus', STATUS_ERROR);

      const errorMessage = error?.response?.data?.message;

      if (errorMessage) {
        commit('setErrors', [errorMessage]);
      } else {
        commit('setErrors', [error.message]);
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
