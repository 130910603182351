import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import user from './modules/user';
import editor from './modules/editor';

Vue.use(Vuex);

const debug = (process.env.NODE_ENV !== 'production' && process.env.MIX_VUE_LOGGER_ENABLE === 'true');

export default new Vuex.Store({
  modules: {
    user,
    editor,
  },
  strict: false,
  plugins: debug ? [createLogger()] : [],
});
