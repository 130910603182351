import Vue from 'vue';

import route from '../../../../vendor/tightenco/ziggy/src/js';
import {Ziggy} from './generated';

Vue.mixin({
  methods: {
    route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
  },
});
