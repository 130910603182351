'use strict';

import {getRedirectUrl} from './utils';
import Data from '../data.json';

const fastSpringBuilderScript = 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.0/fastspring-builder.min.js';
const fastSpringAccessKey = process.env.MIX_BILLING_FS_ACCESS_KEY;
const fastSpringStoreFront = process.env.MIX_BILLING_FS_STOREFRONT;
const fastSpringStoreFrontTest = process.env.MIX_BILLING_FS_STOREFRONT_DEV;

if (typeof window.dataLayerGTM === 'object') {
  sendOrderPlacedGTMEvent(window.dataLayerGTM.id, window.dataLayerGTM.value);
}

window.OnFSWebhook = function (data) {
  if (typeof data.id === 'undefined') return;

  const value = (typeof data.tags.plan_id === 'string' && data.tags.plan_id.toUpperCase() in Data.gaPrices) ? Data.gaPrices[data.tags.plan_id.toUpperCase()] : 9;

  sendOrderPlacedGTMEvent(data.id, value);
};

window.OnFSPopupClosed = function (order) {
  if (order !== null) {
    $.post('/payments/fastspring/check_order', order, (data) => {
      const redirectUrl = getRedirectUrl(data.length ? data : '/', true);
      if (redirectUrl !== null) {
        window.location = redirectUrl;
      }
    });
  }
};


export default function startFastSpring(PlanID, fileID, testPayment) {
  const FastSpringAction = function () {
    $.post('/payments/fastspring/data', {plan_id: PlanID, file_id: fileID}, (data) => {
      if (data === 'duplicate') {
        window.location.href = '/profile';
        return;
      }

      window.fastspring.builder.reset();
      window.fastspring.builder.secure(data.secure_payload, data.secure_key);
      window.fastspring.builder.recognize(data.client);
      window.fastspring.builder.checkout();
    }, 'json');
  };

  if (!$('script#fsc-api').length) {
    $('body').append(`<script id="fsc-api" src="${fastSpringBuilderScript}" data-storefront="${testPayment ? fastSpringStoreFrontTest : fastSpringStoreFront}" data-access-key="${fastSpringAccessKey}" data-popup-closed="OnFSPopupClosed" data-popup-webhook-received="OnFSWebhook" data-debug="${testPayment ? 'true' : 'false'}"></script>`);
  }

  if (typeof window.fastspring === 'undefined') {
    $.ajaxSetup({cache: true});
    $.getScript(fastSpringBuilderScript, FastSpringAction);
  } else {
    FastSpringAction();
  }
}

function sendOrderPlacedGTMEvent(orderID, orderValue) {
  if (typeof window.dataLayer === 'object') {
    window.dataLayer.push({
      event: 'order-placed',
      event_category: 'Checkout',
      event_action: 'Order Complete',
      event_label: orderID,
      event_value: orderValue,
      event_non_interaction: false,
    });

    window.axios.post('/js/gtm', {
      id: orderID,
    });
  }
}
