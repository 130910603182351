export default class Pagination {
  constructor(identifier, max, min) {
    this.identifier = identifier;
    this.max = max;
    this.min = min;

    this.container = $(`.${this.identifier}`);
    this.elements = $(`.${this.identifier} a, .${this.identifier} span`);
    this.active = $(`.${this.identifier} span.active`); // Single element

    this.helperElements = $(`.${this.identifier} span.active, .${this.identifier} .first-link, .${this.identifier} .last-link, .${this.identifier} .static, .${this.identifier} .hidden`);

    this.elementsBeforeActiveCount = this.active.prevAll().length - 3;
    this.elementsAfterActiveCount = this.active.nextAll().length - 3;
  }

  init() {
    if (!this.container.length) {
      return false;
    }
    this.render();
    window.addEventListener('resize', this.render.bind(this), false);
  }

  render() {
    this.elements.removeClass('hidden');

    const singleElementSizeWidth = this.active.outerWidth(true);
    const containerSizeWidth = this.container.innerWidth() - (singleElementSizeWidth * 2);
    const helperSizeWidth = this.helperElements.length * singleElementSizeWidth;

    const availableSizeWidth = containerSizeWidth - helperSizeWidth;

    let elementsShowCount = Math.floor(availableSizeWidth / singleElementSizeWidth);

    if (elementsShowCount > this.max) {
      elementsShowCount = this.max;
    } else if (elementsShowCount < this.min) {
      elementsShowCount = this.min;
    }

    let maxElementsSideCount = Math.floor(elementsShowCount / 2);

    // HOW: Check more available to show
    maxElementsSideCount = (maxElementsSideCount > this.elementsBeforeActiveCount)
      ? maxElementsSideCount + maxElementsSideCount - this.elementsBeforeActiveCount
      : (maxElementsSideCount > this.elementsAfterActiveCount)
        ? maxElementsSideCount + maxElementsSideCount - this.elementsAfterActiveCount
        : maxElementsSideCount;

    // HOW: Newer more than max available to show
    if (maxElementsSideCount > elementsShowCount) {
      maxElementsSideCount = elementsShowCount;
    }

    if (maxElementsSideCount < this.elementsBeforeActiveCount - 1) {
      if (maxElementsSideCount === 0) {
        this.active.prevAll().addClass('hidden');
      } else {
        const elementsBeforeActiveElementHide = this.active.prevUntil().eq(maxElementsSideCount - 1);
        elementsBeforeActiveElementHide
          .prevAll()
          .addClass('hidden');
      }

      $(`.${this.identifier} .first-link`).removeClass('hidden');
      $(`.${this.identifier} .first-dots`).removeClass('hidden');
    } else {
      $(`.${this.identifier} .first-link`).addClass('hidden');
      $(`.${this.identifier} .first-dots`).addClass('hidden');
    }

    if (maxElementsSideCount < this.elementsAfterActiveCount - 1) {
      if (maxElementsSideCount === 0) {
        this.active.nextAll().addClass('hidden');
      } else {
        const elementsAfterActiveElementHide = this.active.nextUntil().eq(maxElementsSideCount - 1);
        elementsAfterActiveElementHide
          .nextAll()
          .addClass('hidden');
      }

      $(`.${this.identifier} .last-link`).removeClass('hidden');
      $(`.${this.identifier} .last-dots`).removeClass('hidden');
    } else {
      $(`.${this.identifier} .last-link`).addClass('hidden');
      $(`.${this.identifier} .last-dots`).addClass('hidden');
    }

    $(`.${this.identifier} .static`).removeClass('hidden');
  }
}
