import 'core-js/stable';
import 'regenerator-runtime/runtime';
// It may be necessary to add promise and iterator polyfills manually for dynamic imports to work
//@see https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env

import './web-vitals';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');

  require('bootstrap');
} catch (e) {
  //Dummy line to suppress ESLint warning about empty block statement :-)
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

const locale = $('html').attr('lang');

window.axios.interceptors.request.use(config => {
  config.params = {
    ...config.params,
    locale: (typeof locale !== 'undefined') ? locale : 'en',
  };

  return config;
});

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//Axios add X-XSRF automatically: https://github.com/laravel/laravel/pull/5083

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
});

if (('webdriver' in navigator && navigator.webdriver) || process.env.NODE_ENV !== 'production') {
  // This is used in E2E testing for "adaptive" pausing

  let pendingAxiosRequests = 0;
  window.emptyAxiosQueue = (pendingAxiosRequests === 0);

  window.axios.interceptors.request.use(config => {
    pendingAxiosRequests++;
    window.emptyAxiosQueue = (pendingAxiosRequests === 0);
    return config;
  });

  window.axios.interceptors.response.use(response => {
    pendingAxiosRequests--;
    window.emptyAxiosQueue = (pendingAxiosRequests === 0);

    return response;
  }, (error) => {
    pendingAxiosRequests--;
    window.emptyAxiosQueue = (pendingAxiosRequests === 0);
    throw error;
  });
}
