import './bootstrap';
import 'sticky-kit/dist/sticky-kit'; //@TODO IE11 fails to load this correctly.
import './navigation';
import './payment';

import Vue from 'vue';

import './ziggy';
import i18n from './i18n';
import store from './store';

new Vue({
  el: '#app',
  store,
  i18n,
});

// require('owl.carousel');
