import textFit from 'textfit';

import {pushUTM} from './libs/Helpers';
import Pagination from './pagination';

/** @TODO
 * This file depends on jquery.scrollbar and sticky-kit that, for space efficiency,
 * imported separately before navigation.js in the corresponded files (editor.js, app.js).
 *
 * This file need to be decomposed on parts (navigator-common.js, navigator-editor.js, navigator-profile.js, ...)
 * to be embedded separately. Required imports should be transferred into these newly-created files.
 */

$(() => {
  'use strict';

  const pagination = new Pagination('pagination', 20, 1);
  pagination.init();

  $('[data-utm]').click(function () {
    pushUTM($(this).data('utm'));
  });

  const $window = $(window);
  const $mainContainer = $('.main-container');
  const $header = $('.appHeader');
  const $footer = $('.appFooter');

  const nav = $('.header .navigation');
  const navHasChild = $('.header .navigation .has-children > a');
  const $navBtn = $('.appHeaderMobileMenuButton');
  const $profileNavBtn = $('.appHeaderMobileProfileMenuButton');
  const $appHeaderMobileNavigationOverlay = $('.app-overlay--header-mobile-navigation');
  const $appHeaderMobileNavigationCloseButton = $appHeaderMobileNavigationOverlay.find('.appHeaderMobileCloseButton');
  const $appHeaderMobileProfileNavigationOverlay = $('.app-overlay--header-profile-mobile-navigation');
  const $appHeaderMobileProfileNavigationCloseButton = $appHeaderMobileProfileNavigationOverlay.find('.appHeaderMobileCloseButton');
  const $footerLanguageSelect = $('.appFooterLanguageSelect__selectElement');

  $window.on('load', () => $('.modal[data-trigger-on-load]').modal('show'));

  const navClose = function () {
    $appHeaderMobileNavigationOverlay.hide();
    $('body').css({
      paddingRight: '',
      overflow: '',
    });
  };

  const hideUserNav = function () {
    const userNav = $('.header .user-nav.show');
    if (userNav.length) {
      userNav.removeClass('show');
      userNav.find('.user-nav-btn').attr('aria-expanded', 'false');
      userNav.find('.dropdown-menu').removeClass('show');
    }
  };

  $footerLanguageSelect.on('change', (event) => {
    window.location.href = $footerLanguageSelect.find(`option[value="${event.target.value}"]`).data('href');
  });

  $appHeaderMobileNavigationCloseButton.on('click', () => {
    navClose();
  });

  $navBtn.on('click', () => {
    if ($appHeaderMobileNavigationOverlay.is(':hidden')) {
      $appHeaderMobileNavigationOverlay.show();
      $('body').css({
        paddingRight: getScrollbarWidth(),
        overflow: 'hidden',
      });
    } else {
      navClose();
    }
  });

  $profileNavBtn.on('click', () => {
    if ($appHeaderMobileProfileNavigationOverlay.is(':hidden')) {
      $appHeaderMobileProfileNavigationOverlay.show();
      $('body').css({
        paddingRight: getScrollbarWidth(),
        overflow: 'hidden',
      });
    } else {
      $appHeaderMobileProfileNavigationOverlay.hide();
      $('body').css({
        paddingRight: '',
        overflow: '',
      });
    }
  });

  $appHeaderMobileProfileNavigationCloseButton.on('click', () => {
    $appHeaderMobileProfileNavigationOverlay.hide();
    $('body').css({
      paddingRight: '',
      overflow: '',
    });
  });

  navHasChild.on('click', function () {
    if ($navBtn.is(':visible')) {
      $(this).parent().toggleClass('active');
      return false;
    }
  }).on('mouseover', function () {
    if ($navBtn.is(':hidden')) {
      hideUserNav();
    }
  });

  $window.on('resize', () => {
    if (nav.hasClass('nav-active') && !$navBtn.is(':visible')) {
      navClose();
    }
  });

  $window.on('scroll', () => {
    hideUserNav(); // hide user navigation dropdown
  });

  const hideAccountMenuMobile = () => {
    const nav = $('.profile-nav');
    if (nav.hasClass('show')) {
      nav.removeClass('show');
      $('body').css({
        paddingRight: '',
        overflow: '',
      });
    }
  };

  $('.user-nav .u-btn-mobile').on('click', () => {
    const nav = $('.profile-nav');
    if (!nav.hasClass('show')) {
      nav.addClass('show');
      $('body').css({
        paddingRight: getScrollbarWidth(),
        overflow: 'hidden',
      });
    } else {
      hideAccountMenuMobile();
    }
  });

  $('.profile-nav > .nav-toggle, .profile-nav .nav-item a').on('click', () => {
    hideAccountMenuMobile();
  });

  $('.message-line .btn-close').on('click', function () {
    $(this).parent().hide();
    return false;
  });

  $('.ui-tooltip').tooltip({
    container: 'body',
  });

  $(document).on('click', '.dropdown-menu > .dropdown-menu-body', function (e) {
    e.stopPropagation();
  });

  function getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    document.body.appendChild(outer);
    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    const widthWithScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
  }

  window.checkIOS = () => navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i);

  function disableAutoZoomIOS() {
    const inputs = 'input.form-control, textarea.form-control';
    const customDPI = 'label[for="imgDpiCustom"]';
    $(document).on({
      mousedown() {
        const input = $(this).next('.form-control');
        if (!input.hasClass('is-focused')) {
          input.css('font-size', '16px');
        }
      },
    }, customDPI);
    $(document).on({
      mousedown() {
        if (!$(this).hasClass('is-focused')) {
          $(this).css('font-size', '16px');
        }
      },
      focus() {
        $(this).css('font-size', '').addClass('is-focused');
      },
      blur() {
        $(this).removeClass('is-focused');
      },
    }, inputs);
  }

  if (window.checkIOS()) {
    disableAutoZoomIOS();
  }

  function uiHeaderSticky() {
    const object = $header;
    if (!object.length || $header.parent().hasClass('index') || $('body').hasClass('editor')) {
      return false;
    }
    object.addClass('sticky-header');
    let isFixed = false;
    const activeClass = 'sticky-active';
    let shouldBeFixed;
    let shouldBeFixedBack;
    let shShow;
    let scrollTop;
    const objectY = object.offset().top;
    let headerHeight;
    let offsetHeight = 300;
    let minHeight;
    const landing = $('.landing-title');
    object.wrap('<div class="sticky-wrapper"></div>');
    // let objWrapper = object.parent('.sticky-wrapper');
    // let wrapperCalc = function () {
    //     let objectHeight = object.height();
    //     objWrapper.css({'min-height': objectHeight});
    // };
    // wrapperCalc();
    $window.on('load resize', () => {
      headerHeight = $header.height();
      minHeight = $mainContainer.height() > $window.height() + headerHeight;
      if (landing.length) {
        offsetHeight = landing.height();
      }
    });
    $window.on('load scroll', () => {
      scrollTop = $window.scrollTop();
      shouldBeFixed = scrollTop && scrollTop > (objectY + headerHeight * 3) && minHeight;
      shouldBeFixedBack = scrollTop && scrollTop > objectY && minHeight;
      if (landing.length) {
        shouldBeFixedBack = shouldBeFixed;
      }
      shShow = scrollTop > offsetHeight;
      if (shouldBeFixed && !isFixed) {
        if (!object.hasClass(activeClass)) {
          object.addClass(activeClass);
        }
        isFixed = true;
      } else if (!shouldBeFixedBack && isFixed) {
        if (object.hasClass(activeClass)) {
          object.removeClass(activeClass);
          if (!landing.length) {
            object.removeClass('sticky-show');
          }
        }
        isFixed = false;
      }
      if (shShow) {
        object.addClass('sticky-show');
      } else {
        if (landing.length) {
          object.removeClass('sticky-show');
        }
      }
    });
  }

  function uiHomepageHero() {
    const hero = $('.hero-wrapper > .hero');
    if (hero.length) {
      const heroFix = 260;
      let heroInnerHeight = 0;
      const calc = function () {
        let windowWidth = $window.width();
        if (windowWidth <= 768) {
          return false;
        }
        let windowHeight = $window.height();
        let windowScroll = $window.scrollTop();
        let heroHeightCalc = windowHeight - heroFix - windowScroll;
        if (heroHeightCalc <= heroInnerHeight) {
          heroHeightCalc = heroInnerHeight;
        }
        hero.css({'height': heroHeightCalc});
      };
      $window.on('load resize', () => {
        hero.css({'height': 'auto'});
        heroInnerHeight = hero.height();
      });
      $window.on('load resize scroll', () => {
        calc();
      });
    }
  }

  function searchTools() {
    const searchInput = $('.pageToolsSearchInput__input');
    if (!searchInput.length) {
      return false;
    }
    const tools = $('.pageToolsGalleryLayout');
    searchInput.keyup(function () {
      // Search filter
      const sInput = this;
      const allList = tools.children('.pageToolsGalleryLink');
      const matchingList = allList.filter((i, li) => {
        const listItemText = $(li).text().toUpperCase();
        const searchText = sInput.value.toUpperCase();
        return ~listItemText.indexOf(searchText);
      });
      allList.hide();
      matchingList.show();
    });
  }

  window.uiScrollbar = () => {
    $('.dropdown-menu-inner').each(function () {
      $(this).addClass('scrollbar-custom');
    });
    const scrollbar = $('.scrollbar-custom').not('.timeline-container');
    const scrollbarOptions = {
      ignoreMobile: true,
    };
    if (scrollbar.length) {
      scrollbar.scrollbar(scrollbarOptions);
      $window.on('load resize', () => {
        scrollbar.each(function () {
          const scrollbar = $(this);
          if (scrollbar.hasClass('sidebar-scroll') || scrollbar.hasClass('editor-content-wrapper')) {
            if ($window.width() < 992) {
              scrollbar.scrollbar('destroy');
            } else {
              scrollbar.scrollbar(scrollbarOptions);
            }
          }
          if (scrollbar.hasClass('filter-section')) {
            if ($window.width() >= 992) {
              scrollbar.scrollbar('destroy');
            } else {
              scrollbar.scrollbar(scrollbarOptions);
            }
          }
        });
      });
    }
  };

  window.uiTimelineScrollbar = () => {
    const scrollElement = $('.timeline-wrapper > .timeline-container.scrollbar-custom');

    if (scrollElement.length) {
      const scrollBarOptions = {
        autoUpdate: true,
        ignoreMobile: true,

        onUpdate() {
          const scrollBarX = $('.timeline-wrapper .scroll-element.scroll-x');

          if (scrollBarX.hasClass('scroll-scrollx_visible') && scrollBarX.is(':visible')) {
            $('.timeline-wrapper').addClass('has-scroll-x');
          } else {
            $('.timeline-wrapper').removeClass('has-scroll-x');
          }
        },

        onInit() {
          if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
          } else {
            const resizeEvent = window.document.createEvent('UIEvents');
            resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(resizeEvent);
          }
        },
      };

      scrollElement.scrollbar(scrollBarOptions);
    }
  };

  function uiScrollTo() {
    const objectLink = $('.scroll-to[href*="#"], .table-of-contents [href*="#"]').not('[href="#"]');
    objectLink.on('click', function () {
      const objectEl = $(this);
      const targetEl = objectEl.attr('href');
      let offsetEl = 0;
      if (targetEl.length) {
        if (objectEl.closest('.table-of-contents').length) {
          offsetEl = 110;
        }
        $('html, body').animate({
          scrollTop: $(targetEl).offset().top - offsetEl,
        }, 1000);
      }
      return false;
    });
  }

  function isInViewport(element) {
    const elementTop = element.offset().top;
    const elementBottom = elementTop + element.height();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  function uiVideoExamples() {
    const object = $('.video-examples');
    if (object.length) {
      object.find('.video-list li').on('click', function () {
        if ($(this).hasClass('current')) {
          return false;
        }
        const tab = $(this);
        const videoEl = object.find('video');
        const video = videoEl.get(0);
        const sources = videoEl.find('source');
        const loader = object.find('.spinner-border');

        tab.addClass('current').siblings().removeClass('current');
        videoEl.removeClass('is-loaded');
        loader.addClass('visible');

        sources.each((index, sourceEl) => {
          const sourceType = $(sourceEl).data('type');
          sourceEl.src = tab.attr(`data-src-${sourceType}`);
          sourceEl.type = tab.attr(`data-type-${sourceType}`);
        });

        video.load();
        const loadTimer = setInterval(() => {
          if (video.readyState === 4) {
            videoEl.addClass('is-loaded');
            loader.removeClass('visible');
            window.demoVideoPlay();
            clearInterval(loadTimer);
          }
        }, 500);
      });
    }
  }

  function uiArticleScroll() {
    const object = $('.article-wrapper');
    if (!object.length) {
      return false;
    }
    $('body').append('<a href="#" id="gototop"></a>'); //@TODO We should think about using <button> here.
    $header.append('<progress id="articleProgress" max="100" value="0"></progress>');
    const btn = $('#gototop');
    const progress = $('#articleProgress');
    const activeClass = ('is-visible');

    const isCTABlockPresent = $('.pageResourcesCallToAction').length > 0;
    if (isCTABlockPresent) {
      btn.addClass('with-resources-cta');
    }

    btn.on('click', () => {
      $('html, body').stop().animate({scrollTop: 0}, 600);
      return false;
    });
    const setProgressMax = function () {
      progress.attr('max', $mainContainer.height() - $window.height() - $footer.height());
    };
    $window.on('load resize', () => {
      setProgressMax();
    });
    setTimeout(() => {
      setProgressMax();
    }, 1000);
    $window.on('load resize scroll', () => {
      const wScroll = $window.scrollTop();
      if (wScroll > 400) {
        btn.addClass(activeClass);
      } else {
        btn.removeClass(activeClass);
      }
      progress.attr('value', $window.scrollTop());

      if (540 < $window.width() && $window.width() >= 992) {
        const scrollBottom = $(document).height() - ($(window).scrollTop() + $(window).height());
        btn.css('bottom', Math.max(30, 110 - scrollBottom));
      } else {
        btn.css('bottom', '');
      }
    });
  }

  function uiArticleShare() {
    const articleWrapper = $('.article-wrapper');
    const articleTitleWrapper = $('.article-title-wrapper');
    const shareButtons = $('.article-title-wrapper .share-buttons');
    const articleBody = $('.article-body');
    if (!shareButtons.length) {
      return false;
    }
    let isFixed = false;
    let shouldBeFixed;
    const initStickyKit = function () {
      shareButtons.stick_in_parent({
        parent: articleWrapper,
        offset_top: 30,
      });
    };
    const calc = function () {
      shouldBeFixed = articleBody.width() < articleWrapper.width();
      if (!shouldBeFixed && isFixed) {
        articleTitleWrapper.css({position: ''});
        shareButtons.trigger('sticky_kit:detach');
        isFixed = false;
      }
      if (shouldBeFixed && !isFixed) {
        initStickyKit();
        shareButtons.on('sticky_kit:bottom', () => {
          articleTitleWrapper.css({position: 'static'});
        }).on('sticky_kit:unbottom', () => {
          articleTitleWrapper.css({position: ''});
        });
        isFixed = true;
      }
    };
    setTimeout(() => {
      calc();
    }, 500);
    $(window).on('resize', () => {
      calc();
    });
  }

  function uiArticleStickyCTAButton() {
    const stickResourcesCTAButton = () => {
      const holder = document.getElementsByClassName('pageResourcesCallToAction')[0];
      const inViewport = (element, targetBottomMargin) => element.getBoundingClientRect().bottom + targetBottomMargin <= (window.innerHeight || document.documentElement.clientHeight);

      if (holder) {
        if (inViewport(holder, 0)) {
          holder.classList.remove('sticky');
        } else {
          holder.classList.add('sticky');
        }
      }
    };

    ['load', 'resize', 'scroll'].forEach(event => window.addEventListener(event, stickResourcesCTAButton));
    stickResourcesCTAButton();
  }

  function uiVideoAutoplay() {
    const object = $('.video-demo .ui-player, .video-examples .ui-player');

    if (!object.length) {
      return false;
    }

    object.each(function () {
      const object = $(this);
      const videoEl = object.find('video');
      const video = videoEl.get(0);
      const btn = object.find('.mc-play');
      const loader = object.find('.spinner-border');

      object.next('.editor-demo').find('input[type="radio"]').each(function () {
        $(this).attr('disabled', true);
      });

      const autoplayCheck = function () {
        if (videoEl.hasClass('is-paused') || !videoEl.hasClass('is-loaded')) {
          return false;
        }
        if (video.paused && isInViewport(object)) {
          if (videoEl.attr('data-speed')) {
            video.playbackRate = videoEl.attr('data-speed');
            videoEl.removeAttr('data-speed');
          }
          video.play();
          videoEl.addClass('is-playing');
          btn.addClass('mc-pause');
        } else if (!video.paused && !isInViewport(object)) {
          video.pause();
          videoEl.removeClass('is-playing');
          btn.removeClass('mc-pause');
        }
      };

      const videoLoad = function () {
        if (!videoEl.hasClass('is-loaded')) {
          video.load();

          const loadTimer = setInterval(() => {
            if (video.readyState === 4) {
              videoEl.addClass('is-loaded');
              loader.removeClass('visible');

              object.next('.editor-demo').find('input[type="radio"]').each(function () {
                $(this).removeAttr('disabled');
              });

              if (videoEl.closest('.video-demo').hasClass('demo-loop') && !videoEl.hasClass('is-loop')) {
                const loop = videoEl.closest('.video-demo').find('.nav-switch').find('input:checked').val() || 3;
                window.demoVideoLoop(loop);
              }

              if (videoEl.closest('.video-demo').hasClass('demo-stop-motion')) {
                const clipRate = videoEl.closest('.video-demo').find('.nav-switch').find('input:checked').val();
                window.demoVideoStopMotion(clipRate);
              }

              // if (videoEl.closest('.video-demo').hasClass('demo-mute')) {
              //   uiDemoMute();
              // }

              autoplayCheck();

              clearInterval(loadTimer);
            }
          }, 500);
        }
      };

      videoLoad();
      // $window.on('load', () => {
      //   videoLoad();
      // });

      $window.on('load resize scroll', () => {
        autoplayCheck();
      });
    });

    $('.ui-player').on('click', () => {
      window.demoVideoPlay();
    });
  }

  function uiDemoFlip() {
    const video = $('.demo-flip video');
    const btn = $('.demo-flip [data-flip]');

    if (!video.length && !btn.length) {
      return false;
    }

    let flipX = false;
    let flipY = true;
    let sx = 1;
    let sy = -1;

    btn.on('click', function () {
      const flip = $(this).data('flip');

      if (flip === 'x') {
        if (!flipX) {
          flipX = true;
          sx = -1;
        } else {
          flipX = false;
          sx = 1;
        }
      }

      if (flip === 'y') {
        if (!flipY) {
          flipY = true;
          sy = -1;
        } else {
          flipY = false;
          sy = 1;
        }
      }

      if (flipX || flipY) {
        video.css('transform', `scale3d(${sx}, ${sy}, 1)`);
      } else {
        video.css('transform', '');
      }
    });
  }

  // function uiDemoMute() {
  //   const container = $('.demo-mute .ui-player');
  //
  //   if (!container.length) {
  //     return false;
  //   }
  //
  //   const video = $('#demoVideo').get(0);
  //   const title = $('#muteControlsTitle');
  //   const btnPlay = $('.demo-mute .editor-demo .mc-play');
  //   const btnMute = $('.demo-mute .editor-demo .mc-mute');
  //   const btnDone = $('.demo-mute .editor-demo .mc-done');
  //
  //   container.on('click', () => {
  //     if (!video.muted) {
  //       btnPlay.addClass('d-none');
  //       btnMute.removeClass('d-none');
  //       title.html(btnMute.attr('data-title'));
  //     }
  //   });
  //
  //   btnPlay.on('click', () => {
  //     container.click();
  //   });
  //
  //   btnMute.on('click', () => {
  //     window.demoVideoMute();
  //     btnMute.addClass('d-none');
  //     btnDone.removeClass('d-none');
  //     title.html(btnDone.attr('data-title'));
  //   });
  // }

  window.demoVideoPlay = () => {
    const player = $('.ui-player');
    const videoEl = player.find('video');
    const video = videoEl.get(0);
    const btn = player.find('.mc-play');
    if (!videoEl.hasClass('is-loaded')) {
      return false;
    }
    if (video.paused) {
      if (videoEl.closest('.video-demo').hasClass('demo-loop') && !videoEl.hasClass('is-loop')) {
        const loop = videoEl.closest('.video-demo').find('.nav-switch').find('input:checked').val();
        window.demoVideoLoop(loop);
      } else {
        video.play();
        videoEl.removeClass('is-paused').addClass('is-playing');
        btn.addClass('mc-pause');
      }
      if (videoEl.closest('.video-demo').hasClass('demo-stop-motion')) {
        const clipRate = videoEl.closest('.video-demo').find('.nav-switch').find('input:checked').val();
        window.demoVideoStopMotion(clipRate);
      }
    } else {
      video.pause();
      videoEl.addClass('is-paused').removeClass('is-playing');
      btn.removeClass('mc-pause');
    }
  };

  window.demoVideoMute = () => {
    const video = document.getElementById('demoVideo');
    if (!video) return;
    video.muted = !video.muted;
  };

  window.demoVideoSpeed = (a) => {
    const video = document.querySelector('#demoVideo.is-loaded');
    if (!video) return;
    video.playbackRate = a;
  };

  window.demoVideoLoop = (a) => {
    const object = $('.demo-loop #demoVideo');
    if (!object.length) {
      return false;
    }
    object.unbind('ended');

    const video = object.get(0);
    const btn = $('.mc-play');
    const loop = a;
    let iterations = 1;
    // console.log('start loop - ' + iterations);

    const restartVideo = function () {
      video.currentTime = 0.01;
      video.play();
      object.removeClass('is-paused').addClass('is-playing is-loop');
      btn.addClass('mc-pause');
    };
    restartVideo();

    object.on('ended', function () {
      // console.log('end loop - ' + iterations);
      if (iterations < loop) {
        restartVideo();
        iterations++;
        // console.log('start loop - ' + iterations);
      } else {
        object.addClass('is-paused').removeClass('is-playing is-loop');
        btn.removeClass('mc-pause');
        video.pause();
        $(this).unbind('ended');
        // console.log('stop loop');
      }
    });
  };

  let demoStopMotionTimer = 0;
  window.demoVideoStopMotion = (clipRate) => {
    // window.console.log('start');
    // window.console.log(clipRate);
    const speedValue = 0.5;

    const video = document.getElementById('demoVideo');
    const canvas = document.getElementById('demoCanvas');
    if (!video || !canvas) {
      return false;
    }

    video.playbackRate = clipRate / speedValue;

    const videoWidth = video.clientWidth;
    const videoHeight = video.clientHeight;

    canvas.style.left = '0';
    canvas.width = videoWidth;
    canvas.height = videoHeight;

    if (demoStopMotionTimer > 0) {
      clearInterval(demoStopMotionTimer);
    }

    canvas.getContext('2d').drawImage(video, 0, 0, videoWidth, videoHeight);
    demoStopMotionTimer = setInterval(
      () => {
        canvas.getContext('2d').drawImage(video, 0, 0, videoWidth, videoHeight);
      },
      speedValue * 1000,
      canvas, video, videoWidth, videoHeight
    );
  };

  window.uiLoaderSticky = () => {
    const object = $('.landing-title .progress-box');
    const header = $('.header .sticky-header');
    if (object.length) {
      let isFixed = false;
      let shouldBeFixed;
      const stickyClass = 'is-sticky';
      $(window).on('load scroll', () => {
        shouldBeFixed = header.hasClass('sticky-show');
        if (shouldBeFixed && !isFixed) {
          object.addClass(stickyClass);
          isFixed = true;
        } else if (!shouldBeFixed && isFixed) {
          object.removeClass(stickyClass);
          isFixed = false;
        }
      });
    }
  };

  window.uiFileSourceDropdown = () => {
    const btn = $('.file-source-btn');

    btn.each(function () {
      const control = $(this);
      const dropdown = control.children('.dropdown');
      const dropdownBtn = dropdown.children('button');
      const dropdownMenu = dropdown.children('.dropdown-menu');
      const dropdownItem = dropdown.children('.dropdown-menu').children('.dropdown-item');
      const dropdownInput = dropdown.children('.dropdown-menu').children('.upload-url').children('input');

      const activeClass = 'show-dropdown';
      let hover = false;
      let clicked = false;

      const showDropdown = function () {
        control.addClass(activeClass);

        let positionCalc = function () {
          let ddBtnHeight = control.height();
          let ddMenuHeight = dropdownMenu.height();
          let spacingTop = dropdown.offset().top - $(window).scrollTop() + ddBtnHeight;
          let spacingBottom = $(window).height() - spacingTop + ddBtnHeight;
          if (spacingBottom >= spacingTop && spacingTop < ddMenuHeight) {
            dropdownMenu.attr('x-placement','bottom-end');
          } else if (spacingBottom < spacingTop && spacingBottom < ddMenuHeight) {
            dropdownMenu.attr('x-placement','top-end');
          } else {
            dropdownMenu.attr('x-placement','auto');
          }
        };
        positionCalc();

      };

      const hideDropdown = function () {
        control.removeClass(activeClass);
        dropdownInput.blur();
        hover = false;
        clicked = false;
      };

      control.on('mouseover', function () {
        if ($navBtn.is(':hidden')) {
          hideUserNav();
        }
      });

      dropdownBtn.on('click', () => {
        clicked = true;
        if (control.hasClass(activeClass)) {
          hideDropdown();
        } else {
          showDropdown();
        }
      });

      dropdown.on('mouseover', () => {
        if (!hover) {
          hover = true;
          setTimeout(() => {
            if (!clicked) {
              showDropdown();
            }
          }, 5);
        }
      }).on('mouseleave', () => {
        hideDropdown();
      });

      dropdownItem.on('click', () => {
        hideDropdown();
      });
    });
  };

  window.uiTextareaAutoExpand = () => {
    const inputs = 'textarea.auto-expand';
    $(document).on({
      input() {
        const offset = this.offsetHeight - this.clientHeight;
        $(this).css('height', '').css('height', this.scrollHeight + offset);
      },
    }, inputs);
  };

  // Fix custom select and dropdown position
  window.uiFixCustomSelect = () => {
    const objects = '.dd-autopos .form-select[data-toggle="dropdown"]';
    $(document).on({
      mousedown() {

        if ($(this).hasClass('show')) {
          return false;
        }

        let ddBtn = $(this);
        let ddContainer = ddBtn.parent();

        let ddList = ddBtn.next('.dropdown-menu');
        let ddListInner = ddList.children('.dropdown-menu-inner');
        let ddListInnerScroll = ddListInner.children('.dropdown-menu-inner');

        let mainBtn = document.querySelector('.editor-footer .editor-button');

        let topOffset = 10; // top extra spacing
        let bottomOffset = 10; // bottom extra spacing
        if (mainBtn !== null && mainBtn.clientWidth === $(window).width()) {
          bottomOffset = 67; // bottom extra spacing on mobile if mainBtn exists
        }

        let positionCalc = function () {

          let ddBtnHeight = ddContainer.height();

          let spacingTop = ddBtn.offset().top - $(window).scrollTop() + ddBtnHeight - topOffset;
          let spacingBottom = $(window).height() - (spacingTop + topOffset) + ddBtnHeight - bottomOffset;

          let ddMaxHeight = 272; // 272px in css for .dropdown-menu and .dropdown-menu-inner

          let setHeight = function () {
            ddList.css({"max-height": ddMaxHeight + 'px'})
              .attr('x-placement','');
            ddListInner.css({"max-height": ddMaxHeight + 'px'});
            if (ddListInnerScroll.length) {
              ddListInnerScroll.css({"max-height": ddMaxHeight + 'px'});
            }
          };

          setHeight();

          if (spacingBottom >= spacingTop || spacingBottom > ddList.height()) {
            if (spacingBottom < ddMaxHeight) {
              ddMaxHeight = spacingBottom;
              setHeight();
            }
            ddList.attr('x-placement','bottom-start');
          } else {
            if (spacingTop < ddMaxHeight) {
              ddMaxHeight = spacingTop;
              setHeight();
            }
            ddList.attr('x-placement','top-start');
          }

        };

        positionCalc();

      },
    }, objects);

  };

  // Tabs Switcher
  window.uiTabsSwitcher = () => {
    let moveIndicator = function(item, indicator) {
      let newWidth = item.outerWidth();
      let distance = item.position().left;
      indicator.css({
        'transform': "translateX(" + distance + "px)",
        'width' : newWidth + 'px'
      });
    };
    const objects = '.tabs-switcher .tab-item';
    $(document).on({
      click: function() {
        let item = $(this);
        if (!item.hasClass('active')) {
          const wrapper = item.closest('.tabs-switcher');
          const container = item.parent();
          const indicator = container.find('.tab-indicator');
          const activeItem = container.find('.tab-item.active');
          if(indicator) {
            if (!wrapper.hasClass('js-loaded')) {
              moveIndicator(activeItem, indicator);
              wrapper.addClass('js-loaded');
            }
            moveIndicator(item, indicator);
          }
          item.siblings().removeClass('active');
          item.addClass('active');
        }
      },
    }, objects);
    let resizeTabSwitcher = function() {
      $('.tabs-switcher.js-loaded').each(function () {
        $(this).removeClass('js-loaded');
      });
    };
    window.addEventListener('resize', resizeTabSwitcher);
  };

  uiHeaderSticky();
  uiHomepageHero();
  searchTools();
  uiScrollTo();
  uiArticleShare();
  uiArticleScroll();
  uiArticleStickyCTAButton();

  uiVideoAutoplay();
  uiVideoExamples();
  uiDemoFlip();

  window.uiScrollbar();
  window.uiTimelineScrollbar();

  window.uiLoaderSticky();
  window.uiFileSourceDropdown();
  window.uiTextareaAutoExpand();
  window.uiFixCustomSelect();
  window.uiTabsSwitcher();
});
