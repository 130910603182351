import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

Pusher.logToConsole = (process.env.NODE_ENV !== 'production');

const echoOptions = {
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY || '',
};

const pusherOptions = {
  httpHost: process.env.MIX_PUSHER_APP_HOST,
  httpPath: process.env.MIX_PUSHER_APP_PATH,

  wsHost: process.env.MIX_PUSHER_APP_HOST,
  wsPort: process.env.MIX_PUSHER_APP_PORT,
  wsPath: process.env.MIX_PUSHER_APP_PATH,
  wssPort: process.env.MIX_PUSHER_APP_PORT,

  enableStats: false,
  forceTLS: (process.env.NODE_ENV === 'production'),
  enabledTransports: ['ws', 'wss'],
};

if (process.env.NODE_ENV !== 'production') {
  pusherOptions.enabledTransports = ['ws'];
  pusherOptions.forceTLS = false;
}

window.Pusher = window.Pusher || Pusher;
const echo = new Echo({...echoOptions, ...pusherOptions});
delete window.Pusher;

export default echo;
